import Tooltip from "../../../basics/tooltip/Tooltip";
import React from "react";
import {withTranslation} from "react-i18next";

function SummaryTemplate({t, header, alerts = [], warnings = [], content, isError = false, isWarning = false}) {
    const showErrorSign = !!isError || !!alerts.length;
    const showWarningSign = !showErrorSign && (!!isWarning || !!warnings.length);
    const className = showErrorSign ? "error" : showWarningSign ? "warning" : "";
    const codes = [...alerts, ...warnings];
    const boolean = [true, false].includes(content);
    return (
        <div className={className}>
            {
                (showErrorSign || showWarningSign) &&
                <Tooltip tooltipContent={
                    <div className={"exclamation"}>
                        {
                            codes.map((code) => (
                                <div>
                                    {t([`notificationsCenter.errorCodes.S${code}`, `notificationsCenter.errorCodes.${code}`, `${code}`])}
                                </div>
                            ))
                        }
                        {
                            showWarningSign && (!codes.length) &&
                            t("outOfSync")
                        }
                    </div>
                }>
                    <div className={"d-inline-block me-1"}>
                        {showErrorSign && <i className={"fas fa-exclamation-triangle error"}/>}
                        {showWarningSign && <i className={"fas fa-exclamation-triangle warning"}/>}
                    </div>
                </Tooltip>
            }
            <div className={"value"}>
                {
                    boolean &&
                    <>
                        {
                            content === true &&
                            <i className={"fas fa-check success"}></i>
                        }
                        {
                            content === false &&
                            <i className={"fas fa-times error"}></i>
                        }
                    </>
                }
                {
                    !boolean && content
                }
            </div>
            <div className={"label"}>{header}</div>
        </div>
    )
}

export default withTranslation()(SummaryTemplate);
