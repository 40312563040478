import * as EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import {cloneDeep, findIndex, findLast, get, last} from "lodash";
import moment from "moment";
import {DICTIONARY_TYPE} from "../constans/general";
import store from "../store/store";
import {
    convertRowsToCycles,
    EventsSortKeys,
    getEventsFromRow,
    preEvents,
} from "./AnimalDocumentsUtils";
import {
    getDaysForRepeatedInsemination,
    getOtherWeights,
    getTimeFromInseminationToPartuition,
} from "./SettingsUtils";
import {isGiltForbidden} from "./AnimalsUtils";
import {USG_STATE} from "@wesstron/utils/Api/constants/usgStates";

export const KILL_TIME = {
    ONE_DAY: {time: 1, type: "days"},
    TWO_WEEKS: {time: 2, type: "weeks"},
    HALF_OF_YEAR: {time: 6, type: "months"},
    THREE_YEARS: {time: 3, type: "years"},
};

export function getKillTime(killTime) {
    if (!killTime) killTime = KILL_TIME.HALF_OF_YEAR;
    let time = moment.tz("Europe/Warsaw").add(killTime.time, killTime.type);
    return parseInt(time.toDate().getTime() / 1000);
}

export function getAllEventsForAnimalFromState(
    AnmID,
    eventType,
    showDeleted = false
) {
    let state = store.getState();
    const {
        events: {eventsForAnimals},
    } = state;
    return (eventsForAnimals.get(AnmID) ?? []).filter(
        (item) =>
            (!showDeleted ? !item.DtaDelTime : item) &&
            item.EvCode === eventType
    );
}

export function getLastEventForAnimalFromState(AnmID, eventType) {
    let events = getAllEventsForAnimalFromState(AnmID, eventType);
    events.sort((a, b) => b.EvTime - a.EvTime);
    return events[0];
}

export function getPigBalance(
    events,
    resetOnInsemination = true,
    toCurrentDay = false,
    ignoreState = false
) {
    let pigBalance = 0;
    let sowEvents = toCurrentDay
        ? events.filter(
              (ev) => ev.EvTime <= moment().endOf("day").toDate().getTime()
          )
        : events.slice(0);
    sowEvents.sort((e1, e2) => e1.EvTime - e2.EvTime);
    sowEvents.sort((e1, e2) => {
        const time1 = +moment(e1.EvTime).startOf("day");
        const time2 = +moment(e2.EvTime).startOf("day");
        if (time1 === time2) {
            return EventsSortKeys[e1.EvCode] - EventsSortKeys[e2.EvCode];
        }
        return time1 - time2;
    });
    for (let ev of sowEvents) {
        switch (ev.EvCode) {
            case EventTypes.PARTURITION:
                try {
                    pigBalance = +ev.EvData.HealthyCnt;
                } catch (e) {
                    console.error(e);
                }
                break;
            case EventTypes.FALL_PIGLETS:
                try {
                    pigBalance -= +ev.EvData.Piglets;
                } catch (e) {
                    console.error(e);
                }
                break;
            case EventTypes.SEPARATION_TO_MOMMY:
            case EventTypes.SEPARATION:
                try {
                    pigBalance -= +ev.EvData.PiCnt;
                } catch (e) {
                    console.error(e);
                }
                break;
            case EventTypes.MOMMY:
                try {
                    pigBalance += +ev.EvData.PiCnt;
                } catch (e) {
                    console.error(e);
                }
                break;
            case EventTypes.SEPARATION_TO_MOMMY_GILTS: {
                if (!ev.EvData.MommyTime) {
                    pigBalance += 1;
                }
                break;
            }
            case EventTypes.TATTOO: {
                try {
                    const animals = get(
                        ev,
                        "EvData.TattooedAnimals",
                        []
                    ).reduce((a1, a2) => a1 + (a2.MommyTime ? 1 : 0), 0);
                    pigBalance -= animals;
                } catch (e) {
                    console.error(e);
                }
                break;
            }
            case EventTypes.INSEMINATION:
                if (resetOnInsemination) {
                    pigBalance = 0;
                }
                break;
            case EventTypes.PARTURITION_STATE: // zgloszenie stanu porodu jest wazniejsze, chyba, ze zgloszono porod po
                // pigBalance = ev.EvData.HealthyCnt || pigBalance; // a co jesli ktos zglosi 0 na stanie?
                // the state of piglets is no longer supported
                if (!ignoreState) pigBalance = ev.EvData.HealthyCnt;
                break;
            default:
                break;
        }
    }
    return pigBalance;
}

export function getPigBalanceByCycle(cycle) {
    const events = [];
    Object.values(cycle).forEach((arr) => {
        if (Array.isArray(arr)) {
            arr.forEach((el) => events.push(el));
        }
    });
    const noParturitionStateBalance = getPigBalance(
        events.filter((e) => e.EvCode !== EventTypes.PARTURITION_STATE)
    );
    const parturitionStateTime = get(
        cycle,
        `[${EventTypes.PARTURITION_STATE}][0].EvTime`,
        0
    );
    return {
        withoutState: noParturitionStateBalance,
        balance: getPigBalance(events),
        fromMommy: getAliveAnimalsAmountFromMommyGiltEvents({}, events),
        tattooed: getAliveAnimalsAmountFromTattooEvents({}, events),
        parturitionStateTime,
    };
}

export function getAllEventsWithEvTimeGreaterThanOrEqualsForAnimalFromState(
    AnmID,
    EvTime
) {
    try {
        let state = store.getState();
        const {
            events: {eventsForAnimals},
        } = state;
        let cp = cloneDeep(eventsForAnimals.get(AnmID));
        cp = cp.filter((item) => item.EvTime >= EvTime && !item.DtaDelTime);
        return cp.sort((a, b) => a.EvTime - b.EvTime);
    } catch (e) {
        console.error(e);
        return [];
    }
}

export function getPigBalanceForSowFromState(
    animal,
    selectedDate,
    includeTattooing = false
) {
    if (!animal) return 0;
    try {
        const lastBirth =
            getLastEventForAnimalFromState(
                animal.AnmID,
                EventTypes.PARTURITION
            ) ||
            getLastEventForAnimalFromState(
                animal.AnmID,
                EventTypes.PARTURITION_STATE
            );
        if (!lastBirth) return 0;
        let evtz = getAllEventsWithEvTimeGreaterThanOrEqualsForAnimalFromState(
            animal.AnmID,
            lastBirth.EvTime
        );
        selectedDate &&
            (evtz = evtz.filter((ev) =>
                moment(+ev.EvTime).startOf("day").isSameOrBefore(selectedDate)
            ));
        const balance = getPigBalance(evtz, true, false, false);
        if (includeTattooing) {
            const tattooedAnimals = getAliveAnimalsAmountFromTattooEvents(
                animal,
                evtz
            );
            return balance - tattooedAnimals;
        }
        return balance;
    } catch (e) {
        console.error(e);
        return 0;
    }
}

export function getFirstInseminationInCycle(events) {
    events.sort((a, b) => +a.EvTime - +b.EvTime);
    let inseminations = events.filter(
        (ev) => ev.EvCode === EventTypes.INSEMINATION
    );
    let firstInsemination = inseminations[0];
    let daysForRepeatedInsemination = getDaysForRepeatedInsemination();
    for (let insemination of inseminations) {
        if (
            moment(insemination.EvTime).diff(firstInsemination.EvTime, "days") >
            daysForRepeatedInsemination
        ) {
            return insemination;
        }
    }
    return firstInsemination;
}

export function getPlannedParturition(events, animal) {
    let firstInsemination = getFirstInseminationInCycle(events);
    //jesli jest inseminacja wyliczamy date planowanego porodu
    if (firstInsemination) {
        const nextEvents = animal.events.filter(
            (event) => firstInsemination.EvTime < event.EvTime
        );
        const nextEventsCodes = nextEvents.map((event) => event.EvCode);
        if (
            (!nextEventsCodes.includes(EventTypes.PARTURITION) &&
                !nextEventsCodes.includes(EventTypes.NO_PREGNANCY) &&
                !nextEventsCodes.includes(EventTypes.USG)) ||
            (nextEventsCodes.includes(EventTypes.USG) &&
                last(
                    nextEvents
                        .filter((event) => event.EvCode === EventTypes.USG)
                        .sort((a, b) => a.EvTime - b.EvTime)
                ).EvData.Pregnant !== 0 &&
                last(
                    nextEvents
                        .filter((event) => event.EvCode === EventTypes.USG)
                        .sort((a, b) => a.EvTime - b.EvTime)
                ).EvData.Pregnant !== false &&
                !nextEventsCodes.includes(EventTypes.PARTURITION) &&
                !nextEventsCodes.includes(EventTypes.NO_PREGNANCY))
        ) {
            const timeFromInseminationToParturition =
                getTimeFromInseminationToPartuition();
            return +moment(+firstInsemination.EvTime)
                .startOf("day")
                .add(timeFromInseminationToParturition, "days");
        }
    }
}

/**
 * Funkcja zwracająca date planowanego porodu liczoną od dnia pierwszej inseminacji
 * @returns {string}
 * @param animal
 */
export function getPlannedParturitionForASow(animal) {
    if (animal && animal.events && animal.events.length > 0) {
        const cycles = preEvents(animal.events).resultTable;
        const lastCycle = last(cycles);
        if (
            lastCycle &&
            lastCycle[EventTypes.INSEMINATION].length > 0 &&
            [
                ...lastCycle[EventTypes.SOW_CYCLES],
                ...lastCycle[EventTypes.SEPARATION],
            ].length === 0
        ) {
            return moment
                .utc(lastCycle[EventTypes.INSEMINATION][0].EvTime)
                .startOf("day")
                .add(getTimeFromInseminationToPartuition(), "days")
                .format("L");
        }
    }
    return "";
}

export const getTranslationPath = (type) => {
    const types = {
        [DICTIONARY_TYPE.clients]: "newSettings.dictionary.manage.item.clients",
        [DICTIONARY_TYPE.fallReasons]:
            "newSettings.dictionary.manage.item.fallReasons",
        [DICTIONARY_TYPE.feedingCurves]:
            "newSettings.dictionary.manage.item.feedingCurves", // nie istnieje
        [DICTIONARY_TYPE.forageType]:
            "newSettings.dictionary.manage.item.forageType", // nie istnieje
        [DICTIONARY_TYPE.graftingReason]:
            "newSettings.dictionary.manage.item.graftingReason",
        [DICTIONARY_TYPE.medicine]: "medicines", // leki sa inaczej robione
        [DICTIONARY_TYPE.noPreganancy]:
            "newSettings.dictionary.manage.item.noPreganancy",
        [DICTIONARY_TYPE.race]: "newSettings.dictionary.manage.item.race",
        [DICTIONARY_TYPE.selectionReason]:
            "newSettings.dictionary.manage.item.selectionReason",
        [DICTIONARY_TYPE.suppliers]:
            "newSettings.dictionary.manage.item.suppliers",
        [DICTIONARY_TYPE.weighting]:
            "newSettings.dictionary.manage.item.weightingReasons",
        [DICTIONARY_TYPE.controlLists]: "controlLists.mainView.header",
        [DICTIONARY_TYPE.taskCategory]:
            "newSettings.dictionary.manage.item.taskCategory",
        [DICTIONARY_TYPE.graftingProgram]: "logsView.details.graftingPrograms",
        [DICTIONARY_TYPE.files]: "newSettings.dictionary.manage.item.files",
        [DICTIONARY_TYPE.comments]: "printSelectedAnimalsModal.comments",
        [DICTIONARY_TYPE.crossBreeding]:
            "newSettings.dictionary.manage.item.crossBreeding",
        [DICTIONARY_TYPE.sellers]: "seller",
    };
    return types[type] || null;
};

export function getCycleForInsemination(
    insemination,
    events,
    animal,
    cycles = null
) {
    if (!cycles) {
        cycles = preEvents(events, animal);
    }
    for (let cycle of cycles.cycleTable) {
        if (
            cycle[EventTypes.INSEMINATION].find(
                (item) => item.EvID === insemination.EvID
            )
        ) {
            return cycle;
        }
    }
}

/**
 * funkcja zwraca wagę z eventu kastracji który jest "powiązany" ze zgłoszonym porodem
 * @param parturition
 * @param cycles
 * @param cycle
 * @returns {number}
 */
export function getParturitionWeight(parturition, cycles, cycle) {
    let parturitionWeight = 0;
    let parturitionCycle =
        cycle ||
        cycles.find((cycle) =>
            get(cycle, `${EventTypes.PARTURITION}`, []).some(
                (p) => p.EvID === parturition.EvID
            )
        );
    if (parturitionCycle) {
        const castration = findLast(
            get(parturitionCycle, `${EventTypes.CASTRATION}`, []),
            (ca) => get(ca, "EvData.Weighting", false)
        );
        if (castration) {
            parturitionWeight = get(castration, "EvData.Weight", 0);
        }
    }
    return parturitionWeight;
}

export function checkIfCanRemoveEventFromCycle(cycleTable, eventToRemove) {
    const {EvID, EvCode} = eventToRemove;
    if (EvCode === EventTypes.CASTRATION) return true; // tylko wagi porodowe sa widoczne na karcie
    const indexOfRow = findIndex(cycleTable, (row) =>
        row[EvCode].some((e) => e.EvID === EvID)
    );
    if (indexOfRow < cycleTable.length - 1) return false;
    const cycleEvents = [];
    for (const row of cycleTable) cycleEvents.push(...getEventsFromRow(row));
    return (
        cycleEvents.filter(
            (e) =>
                ![
                    EventTypes.CASTRATION,
                    EventTypes.TATTOO,
                    EventTypes.PARTURITION_STATE,
                    EventTypes.COMMENTS,
                ].includes(e.EvCode) && e.EvTime > eventToRemove.EvTime
        ).length === 0
    );
}

export function getEventsForAnimalsFattening() {
    return [
        EventTypes.INSERTION,
        EventTypes.FALL,
        EventTypes.SELL,
        EventTypes.TRANSFER,
    ];
}

export function getTattooEventsForAnimal(events) {
    return events.filter(
        ({EvCode, EvData} = {}) =>
            (EvCode === EventTypes.TATTOO &&
                get(EvData, "TattooedAnimals", []).some(
                    (gilt) => !isGiltForbidden(gilt)
                )) ||
            (EvCode === EventTypes.SEPARATION_TO_MOMMY_GILTS &&
                !isGiltForbidden(EvData))
    );
}

export function getAliveAnimalsFromTattoo(sow = {}, event = {}) {
    const animals = [];
    if (event.EvCode === EventTypes.TATTOO) {
        for (const animal of get(event, "EvData.TattooedAnimals", [])) {
            if (isGiltForbidden(animal)) continue;
            animals.push({
                AnmNo1: animal.AnmNo1,
                RFID: animal.RFID,
                SowID: sow.AnmID,
                PlcmntID: sow.PlcmntID,
                EvID: event.EvID,
                TattooTime: event.EvTime,
            });
        }
    }
    return animals;
}

export function getAliveAnimalsFromMommyGilt(sow = {}, event = {}) {
    const animals = [];
    if (
        event.EvCode === EventTypes.SEPARATION_TO_MOMMY_GILTS &&
        !isGiltForbidden(event.EvData)
    ) {
        animals.push({
            AnmNo1: event.EvData.AnmNo1,
            RFID: event.EvData.RFID,
            SowID: sow.AnmID,
            PlcmntID: sow.PlcmntID,
            EvID: event.EvID,
            TattooTime: event.EvTime,
        });
    }
    return animals;
}

export function getAliveAnimalsAmountFromTattooEvents(animal, events) {
    return events.reduce(
        (summary, tattoo) =>
            summary + getAliveAnimalsFromTattoo(animal, tattoo).length,
        0
    );
}

export function getAliveAnimalsAmountFromMommyGiltEvents(animal, events) {
    return events.reduce(
        (summary, mommyGilt) =>
            summary + getAliveAnimalsFromMommyGilt(animal, mommyGilt).length,
        0
    );
}

export function getAliveGiltsAmount(animal, tattooEvents, giltEvents) {
    return Math.max(
        getAliveAnimalsAmountFromTattooEvents(animal, tattooEvents) +
            getAliveAnimalsAmountFromMommyGiltEvents(animal, giltEvents),
        0
    );
}

export function getMamasFromTattooCycle(cycle) {
    const mommyEvents = {subtracted: [], added: []};
    for (const key in cycle) {
        if (
            ![EventTypes.TATTOO, EventTypes.SEPARATION_TO_MOMMY_GILTS].includes(
                key
            )
        )
            continue;
        for (const event of cycle[key]) {
            if (event.EvCode === EventTypes.TATTOO) {
                const subtractedGilts = get(event, "EvData.TattooedAnimals", [])
                    .filter((gilt) => gilt.MommyTime)
                    .map((gilt) => ({
                        EvTime: gilt.MommyTime,
                        EvCode: EventTypes.TATTOO,
                        EvData: {AnmNo1: gilt.AnmNo1},
                    }));
                mommyEvents.subtracted.push(...subtractedGilts);
            } else if (event.EvCode === EventTypes.SEPARATION_TO_MOMMY_GILTS) {
                if (event.EvData.MommyTime) mommyEvents.subtracted.push(event);
                else mommyEvents.added.push(event);
            }
        }
    }
    return mommyEvents;
}

export function getLocationDaysForGroup(
    selectedItem,
    animalEvents = [],
    groupBirth
) {
    const fatteningEvents = getEventsForAnimalsFattening();
    const EventsSortKeys = {
        [EventTypes.INSERTION]: 1,
        [EventTypes.TRANSFER]: 2,
        [EventTypes.SELL]: 3,
        [EventTypes.FALL]: 3,
    };
    const _events = animalEvents.filter(({EvCode, EvTime}) =>
        fatteningEvents.includes(EvCode)
    );
    _events.sort((e1, e2) => e1.EvTime - e2.EvTime);
    _events.sort((e1, e2) => {
        const time1 = +moment(e1.EvTime).startOf("day");
        const time2 = +moment(e2.EvTime).startOf("day");
        if (time1 === time2) {
            return EventsSortKeys[e1.EvCode] - EventsSortKeys[e2.EvCode];
        }
        return time1 - time2;
    });
    if (_events.length === 0) return [];
    const locations = {};
    const dates = [];
    const otherWeights = getOtherWeights();
    const getDays = (locationId) => {
        if (!locations[locationId]) locations[locationId] = [];
        return locations[locationId];
    };
    const getItemType = (code, payload, subtract, type = "") => {
        if (type || code === EventTypes.TRANSFER) return type;
        return subtract ? "error" : "success";
    };
    const getWeight = (code, time, payload) => {
        const weight = get(payload, "Weight", 0);
        if (weight || otherWeights.length === 0) return weight;
        const timeFromBirth = Math.max(
            0,
            moment(time).diff(moment(groupBirth), "weeks")
        );
        return otherWeights[Math.min(timeFromBirth, otherWeights.length - 1)];
    };
    const generateItem = (
        {AnmID, EvCode, EvTime, EvData},
        subtract,
        amount,
        type
    ) => {
        const isRemovedFromGroup =
            EvCode === EventTypes.TRANSFER && EvData.RemovedReason;
        return {
            AnmID,
            EvTime,
            EvCode,
            Type: getItemType(EvCode, EvData, subtract, type),
            Amount: amount,
            Weight: getWeight(EvCode, EvTime, EvData),
            RemovedReason: isRemovedFromGroup ? isRemovedFromGroup : undefined,
        };
    };
    const getElement = (locationDays, date) =>
        locationDays.find((x) => x.date === date);
    const createLactationElement = (
        subtract,
        anmCnt,
        locationId,
        date,
        event,
        type
    ) => {
        const isInsertionEvent = event.EvCode === EventTypes.INSERTION;
        if (
            isInsertionEvent &&
            _events.some(
                (e) =>
                    e.EvCode === EventTypes.INSERTION &&
                    e.AnmID === event.EvData.OldAnmID
            )
        )
            return;
        if (!dates.includes(date)) dates.push(date);
        let amount = anmCnt;
        if (subtract) amount = -amount;
        const locationDays = getDays(locationId);
        const dateElement = getElement(locationDays, date);
        const item = generateItem(event, subtract, amount, type);
        if (dateElement) {
            dateElement.value = dateElement.value + amount;
            dateElement.items.push(item);
        } else {
            locationDays.push({
                date,
                value:
                    get(locationDays[locationDays.length - 1], "value", 0) +
                    amount,
                items: [item],
            });
        }
    };
    for (const event of _events) {
        const {EvTime, EvCode, AnmCnt, EvData} = event;
        const destinationId = EvData.PlcmntID || EvData.DstID;
        const amount = EvData.AnmCnt || AnmCnt;
        const date = moment(EvTime).startOf("day").toDate().getTime();
        if (EvCode === EventTypes.TRANSFER) {
            const selectedGroupID = selectedItem.AnmGrp;
            const destinationGroupID =
                EvData?.NewGroupID ?? EvData?.GroupTransfer ?? selectedGroupID;
            const sourceGroupID = EvData?.OldGroupID ?? selectedGroupID;
            const transferBetweenGroups = destinationGroupID !== sourceGroupID;
            console.log(
                "transfer[%s](sourceGroupID=%s, destinationGroupID=%s)",
                amount,
                sourceGroupID,
                destinationGroupID
            );
            // dodajemy
            if (destinationGroupID === selectedGroupID) {
                createLactationElement(
                    false,
                    amount,
                    EvData.DstID,
                    date,
                    event,
                    transferBetweenGroups ? "success" : ""
                );
            }
            // zabieramy
            if (sourceGroupID === selectedGroupID) {
                createLactationElement(
                    true,
                    amount,
                    EvData.SrcID,
                    date,
                    event,
                    transferBetweenGroups ? "error" : ""
                );
            }
            // tutaj nie wiem co sie dzieje
            // const newGroupID = EvData?.NewGroupID;
            // const oldGroupID = EvData?.OldGroupID;
            // const groupTransfer = EvData?.GroupTransfer;
            // const groupID = selectedItem.AnmGrp;
            // const isGroupTransfer = (!newGroupID && !oldGroupID) || (groupTransfer === groupID && (oldGroupID !== groupID || !newGroupID));
            // if (isGroupTransfer) {
            //     createLactationElement(true, amount, EvData.SrcID, date, event, "");
            //     createLactationElement(false, amount, EvData.DstID, date, event, "");
            // } else if (newGroupID === groupID || (oldGroupID && oldGroupID !== groupID)) {
            //     createLactationElement(false, amount, destinationId, date, event, "success");
            // } else if (oldGroupID === groupID) {
            //     createLactationElement(true, amount, EvData.SrcID, date, event, "error");
            // }
        } else {
            createLactationElement(
                [EventTypes.FALL, EventTypes.SELL].includes(EvCode),
                amount,
                destinationId,
                date,
                event
            );
        }
    }
    // filling in missing values
    const data = {};
    for (const locationId in locations) {
        const dataKey = locationId;
        data[dataKey] = [];
        for (const date of dates) {
            const element = getElement(locations[locationId], date);
            if (element) data[dataKey].push(element);
            else {
                const datesBefore = locations[locationId].filter(
                    (d) => d.date < date
                );
                const datesAfter = locations[locationId].filter(
                    (d) => d.date > date
                );
                data[dataKey].push({
                    date,
                    value:
                        datesBefore.length && datesAfter.length
                            ? datesBefore[datesBefore.length - 1].value
                            : "",
                });
            }
        }
    }
    return data;
}

export function getDetailedLocationDaysForGroup(data) {
    const EventsSortKeys = {
        [EventTypes.INSERTION]: 1,
        [EventTypes.TRANSFER]: 2,
        [EventTypes.SELL]: 3,
        [EventTypes.FALL]: 3,
    };
    const detailedData = [];
    const locations = (() => {
        const _locations = {};
        for (const locationId in data)
            _locations[`location_${locationId}`] = {amount: null, animals: []};
        return _locations;
    })();
    const getRow = (date, final = false, anmID) => {
        const row = {
            date: +moment(date).startOf("day"),
            [EventTypes.INSERTION]: {amount: null, anmID},
            [EventTypes.FALL]: {amount: null, anmID},
            [EventTypes.SELL]: {amount: null, anmID},
            [EventTypes.TRANSFER]: {amount: null, anmID},
            summary: {amount: null, code: null, final},
            weight: {amount: null},
            ...JSON.parse(JSON.stringify(locations)),
        };
        detailedData.push(row);
        return row;
    };

    for (const locationId in data) {
        for (const locationElement of data[locationId]) {
            if (!locationElement.items) continue;
            for (const {
                AnmID,
                EvTime,
                EvCode,
                Amount,
                Type,
                Weight,
                RemovedReason,
            } of locationElement.items) {
                const row = getRow(EvTime, false, AnmID);
                row.summary.amount = Amount;
                row.summary.code = EvCode;
                row.summary.type = Type;
                row.weight.amount = Weight;
                row[EvCode].amount = Amount;
                row[EvCode].type = Type;

                row[`location_${locationId}`].amount = Amount;
                row[`location_${locationId}`].animals.push({
                    id: AnmID,
                    amount: Amount,
                });
                if (EvCode === EventTypes.TRANSFER) {
                    row[EvCode].removedReason = !!RemovedReason;
                }
            }
        }
    }

    detailedData.sort((a, b) => {
        if (a.date === b.date) {
            const sortKey1 = EventsSortKeys[a.summary.code];
            const sortKey2 = EventsSortKeys[b.summary.code];
            if (sortKey1 === sortKey2)
                return Math.abs(a.summary.amount) - Math.abs(b.summary.amount);
            return (
                EventsSortKeys[a.summary.code] - EventsSortKeys[b.summary.code]
            );
        }
        return a.date - b.date;
    });

    const summaryRow = getRow(null, true, null);
    let previousRow = null;

    for (const row of detailedData) {
        const {date, ...rest} = row;
        for (const key in rest) {
            const locationKey = key.startsWith("location");
            const currentAmount = get(previousRow, `[${key}].amount`, 0);
            const currentAnimals = get(previousRow, `[${key}].animals`, []);
            if (!key.startsWith("location") && key !== "weight") {
                summaryRow[key].amount += currentAmount;
            }
            if (previousRow && (locationKey || key === "summary")) {
                if (key === "summary" && !rest[key].type) {
                    rest[key].amount = previousRow[key].amount || 0;
                } else {
                    rest[key].amount += previousRow[key].amount || 0;
                    if (locationKey) {
                        const newAnimals = [];
                        const oldAnimals = [
                            ...rest[key].animals,
                            ...currentAnimals,
                        ];
                        for (const animal of oldAnimals) {
                            const existingAnimal = newAnimals.find(
                                ({id}) => id === animal.id
                            );
                            if (existingAnimal) {
                                existingAnimal.amount += animal.amount;
                            } else {
                                newAnimals.push(animal);
                            }
                        }
                    }
                }
            }
        }
        previousRow = rest;
    }
    return {detailedData, locations};
}

export const getClosestEventsByTime = (
    events,
    time,
    {excludeEventIds = [], eventCodes = []} = {}
) => {
    const result = {
        next: null,
        prev: null,
    };
    let nextTs = Number.MAX_SAFE_INTEGER;
    let prevTs = 0;
    for (let event of events) {
        const {EvTime, EvCode, EvID} = event;
        if (eventCodes.length && !eventCodes.includes(EvCode)) continue;
        if (excludeEventIds.includes(EvID)) continue;
        if (EvTime >= time) {
            if (nextTs >= EvTime) {
                result.next = event;
                nextTs = EvTime;
            }
        }
        if (EvTime <= time) {
            if (prevTs <= EvTime) {
                result.prev = event;
                prevTs = EvTime;
            }
        }
    }
    console.log(result.prev, result.next, "closest events");
    return result;
};

export function changeEventTypeToRepeated(type) {
    switch (type) {
        case USG_STATE.POSITIVE:
            return USG_STATE.REPEAT_POSITIVE;
        case USG_STATE.NEGATIVE:
            return USG_STATE.REPEAT_NEGATIVE;
        default:
            return type;
    }
}

export function getEventCycle(animal, events, toDate) {
    if (toDate)
        events = events.filter(
            (e) => e.EvTime <= toDate.clone().endOf("day").toDate().getTime()
        );
    let cycles = convertRowsToCycles(preEvents(events, animal).cycleTable);
    return cycles[cycles.length - 1];
}

function getPigBalanceInCycle(animal, events, selectedDate) {
    try {
        let specificCycle = getEventCycle(animal, events, moment(selectedDate));
        let eventsInCycle = [];
        for (let key in specificCycle) {
            if (
                specificCycle.hasOwnProperty(key) &&
                Array.isArray(specificCycle[key])
            ) {
                eventsInCycle.push(...specificCycle[key]);
            }
        }
        return {
            cycle: specificCycle,
            balance: getPigBalance(eventsInCycle, true, false, false),
            fromMommy: getAliveAnimalsAmountFromMommyGiltEvents(
                animal,
                eventsInCycle
            ),
            tattooed: getAliveAnimalsAmountFromTattooEvents(
                animal,
                eventsInCycle
            ),
        };
    } catch (e) {
        console.error(e);
        return {cycle: null, balance: 0, tattooed: 0};
    }
}

export function getPigBalanceInSpecificCycle(
    animal,
    events,
    selectedDate,
    includeTattooing
) {
    const pigBalance = getPigBalanceInCycle(animal, events, selectedDate);
    if (includeTattooing) {
        return {
            balance:
                pigBalance.balance - pigBalance.tattooed - pigBalance.fromMommy,
            tattooed: pigBalance.tattooed + pigBalance.fromMommy,
        };
    }
    return pigBalance.balance;
}
