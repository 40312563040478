import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import RoleTypes, * as UserRoles from "@wesstron/utils/Api/constants/roleTypes";
import { get, isArray, isNil, isString, memoize, pick } from "lodash";
import memoizeOne from "memoize-one";
import { createSelector } from 'reselect';
import { CurveDayShowingType, CurveType } from "../constans/feedingTypes";
import { LicenseItemCounts } from "../constans/licenseCounts";
import { LicPackageKeys, LicPackageLevel } from "../constans/roles";
import { isInFeedingTab } from '../utils/DispenserNRFUtils';
import { getCurveDayShowingType, sortStands } from "../utils/FeedingUtils";
import { enhancedComparer } from "../utils/TextUtils";
import { makeIsEqualArrayByItemProperty } from "../utils/Utils";
import { getActiveAnimals } from "./animalSelector";
import { getBuildings } from "./buildingsSelector";
import { getLicensePackage, isReadOnlyByPlacementID, roleSelector } from "./roleSelector";
import utilSelectors from "./utilSelectors";


export const FeedingSortType = {
    BY_ANIMAL_NUMBER_ASC: 'SORT_BY_ANIMAL_NUMBER_ASC',
    BY_ANIMAL_NUMBER_DESC: 'SORT_BY_ANIMAL_NUMBER_DESC',
    BY_USAGE_ASC: 'SORT_BY_USAGE_ASC',
    BY_USAGE_DESC: 'SORT_BY_USAGE_DESC',
    BY_ACTIVITY_ASC: 'SORT_BY_ACTIVITY_ASC',
    BY_ACTIVITY_DESC: 'SORT_BY_ACTIVITY_DESC',
    BY_DEFAULT_ASC: 'SORT_BY_DEFAULT_ASC',
    BY_DEFAULT_DESC: 'SORT_BY_DEFAULT_DESC',
    BY_PERCENTAGE_ASC: 'SORT_BY_PERCENTAGE_ASC',
    BY_PERCENTAGE_DESC: 'SORT_BY_PERCENTAGE_DESC'
};

// const getId = (state, props) =>
//     props.id;

export const getSort = (state, { CID }) =>
    CID && state.feeding.feeding[CID] ? state.feeding.feeding[CID].feedingSort : FeedingSortType.BY_NAME_ASC;

const getChamber = (state, { chamber }) =>
    chamber;

export const getFilter = (state, { CID }) =>
    CID && state.feeding.feeding[CID] ? state.feeding.feeding[CID].feedingFilter : "";

export const _getItems = (state) =>
    state.feeding.feeding;

const _getChamberID = (state, { CID }) => CID;

export const isSelected = (f = {}) => !!f.feed && !!f.selected;

const isReadOnly = (state, { CID }) => isReadOnlyByPlacementID(state, RoleTypes.DEVICE_FEEDING_WRITE, CID);


const getDevicesRFID = (state, { CID }) =>
    CID && state.feeding.feeding[CID] ? Object.values(state.feeding.feeding[CID].devices) : [];


const pickKeys = (obj) => pick(obj, ["animal.AnmNo1", "name", "animal.RFID"]);

export const getItems = createSelector([_getItems, _getChamberID], (feeding, CID) => {
    return CID && feeding[CID] ? Object.values(feeding[CID].data) : [];
});

export const getFeedingForPigs = state => state.feeding.feedingForPig.feeding;

export const getFeedingForPig = (state, AnmID) => state.feeding.feedingForPig.feeding[AnmID] || null;

export const getFeedingForPigLoading = state => state.feeding.feedingForPig.loading;

export const getFeedingForPigLoadingByAnmID = (state, AnmID) => state.feeding.feedingForPig.loading[AnmID] || {};

export const getLastSync = state => state.feeding.feedingForPig.lastSync;

export const isSyncingData = state => state.feeding.feedingForPig.syncing;

export const getSyncAmount = state => state.feeding.feedingForPig.amount;

export const getSyncInserted = state => state.feeding.feedingForPig.inserted;

export const getFeedingByBoxes = createSelector([getItems, getChamber], (feeding, chamber = {}) => {
    const { IndividualFeeding, StandsInRow, StandsOrder } = chamber;
    console.log(feeding, chamber, "DKDSOAKSODAS");
    if (IndividualFeeding) {
        return sortStands(feeding, StandsInRow, StandsOrder);
    } else {
        return [];
    }
});

export const makeGetFeedingSorted = () => createSelector(
    [getSort, getItems, getFilter],
    (feedingSort, feeding, filter) => {
        let sortFunc;
        switch (feedingSort) {
            case FeedingSortType.BY_ANIMAL_NUMBER_DESC:
            case FeedingSortType.BY_ANIMAL_NUMBER_ASC:
                sortFunc = (f1, f2) =>
                    enhancedComparer(get(f1, "animal.AnmNo1"), get(f2, "animal.AnmNo1"), {
                        numeric: true,
                        ascending: feedingSort.endsWith("_ASC")
                    });
                break;
            case FeedingSortType.BY_USAGE_DESC:
            case FeedingSortType.BY_USAGE_ASC:
                sortFunc = (f1, f2) =>
                    enhancedComparer(get(f1, "feed.usage"), get(f2, "feed.usage"), {
                        numeric: true,
                        ascending: feedingSort.endsWith("_ASC")
                    });
                break;
            case FeedingSortType.BY_ACTIVITY_DESC:
            case FeedingSortType.BY_ACTIVITY_ASC:
                sortFunc = (f1, f2) =>
                    enhancedComparer(get(f1, "lastSeen.time"), get(f2, "lastSeen.time"), {
                        numeric: true,
                        ascending: !feedingSort.endsWith("_ASC")
                    });
                break;
            case FeedingSortType.BY_DEFAULT_DESC:
            case FeedingSortType.BY_DEFAULT_ASC:
                sortFunc = (f1, f2) =>
                    enhancedComparer(get(f1, "name"), get(f2, "name"), {
                        numeric: true,
                        ascending: feedingSort.endsWith("_ASC")
                    });
                break;
            case FeedingSortType.BY_PERCENTAGE_DESC:
            case FeedingSortType.BY_PERCENTAGE_ASC:
                sortFunc = (f1, f2) =>
                    enhancedComparer(
                        isNil(get(f1, "feed.usage")) ? null : (get(f1, "feed.usage", 0) / (get(f1, "feed.plannedUsage", 0) || 3000))
                        , isNil(get(f2, "feed.usage")) ? null : (get(f2, "feed.usage", 0) / (get(f2, "feed.plannedUsage", 0) || 3000))
                        , { numeric: true, ascending: feedingSort.endsWith("_ASC") });
                break;
            default:
                sortFunc = (f1, f2) => 0;
                break;
        }
        return (filter ? feeding.filter(f => JSON.stringify(pickKeys(f)).toLowerCase().includes(filter.toLowerCase())) : feeding).sort(sortFunc);
    }
);

export const getFeedingSelected = createSelector(
    [getItems, isReadOnly],
    (feeding, readOnly) => {
        return readOnly ? [] : feeding.slice(0).filter(f => isSelected(f));
    }
);

export const getFeedingRFIDDevices = createSelector([getDevicesRFID], (devices) => {
    return devices.sort((o1, o2) => o1.device.Address - o2.device.Address);
});

const getOnReportAction = (state, props) => {
    if (props?.overrideGeneral) return props.overrideGeneral;
    return state.settings && state.settings.general ? state.settings.general : {};
};


const getCurves = (state) =>
    state.settings.feedingCurves;

const getSchedule = (state) =>
    state.settings.feedingSchedules;

const getForages = (state) =>
    state.settings.forage;

export const getFeedingCurves = createSelector([getCurves, getLicensePackage], (curves, license) => {
    switch (license[LicPackageKeys.DISPENSER]) {
        case LicPackageLevel.EXTENDED:
            return curves.filter(c => (c.SetData.Index < LicenseItemCounts.FEEDING_CURVE_EXTENDED) && (c.SetData.Index >= 0));
        case LicPackageLevel.BASIC:
            return curves.filter(c => (c.SetData.Index < LicenseItemCounts.FEEDING_CURVE_BASIC) && (c.SetData.Index >= 0));
        case LicPackageLevel.NO_ACCESS:
        default:
            return [];
    }
});

export const getFeedingSchedules = createSelector([getSchedule, getLicensePackage], (schedules, license) => {
    switch (license[LicPackageKeys.DISPENSER]) {
        case LicPackageLevel.EXTENDED:
            return schedules.filter(c => (c.SetData.Index < LicenseItemCounts.FEEDING_SCHEDULE_EXTENDED) && (c.SetData.Index >= 0));
        case LicPackageLevel.BASIC:
            return schedules.filter(c => (c.SetData.Index < LicenseItemCounts.FEEDING_SCHEDULE_BASIC) && (c.SetData.Index >= 0));
        case LicPackageLevel.NO_ACCESS:
        default:
            return [];
    }
});

/**
 * @deprecated use just getForages since amount lock was removed
 * 
 */
export const getFeedingForages = createSelector([getForages, /*getLicensePackage*/], (forages, /*license*/) => {
    // switch (license[LicPackageKeys.DISPENSER]) {
    //     case LicPackageLevel.EXTENDED:
    //         return forages.filter(c => (c.SetData.Index < LicenseItemCounts.FEEDING_FORAGE_EXTENDED));
    //     case LicPackageLevel.BASIC:
    //         return forages.filter(c => (c.SetData.Index < LicenseItemCounts.FEEDING_FORAGE_BASIC));
    //     case LicPackageLevel.NO_ACCESS:
    //     default:
    //         return [];
    // }
    return forages;
});

export const onReportParturitionData = createSelector([getFeedingCurves, getOnReportAction], (curves, general) => {
    const {
        DefaultParturitionCurveDay: DefaultParturitionCurveDayWST = 1,
        DefaultParturitionCurveNumber: DefaultParturitionCurveNumberWST = 0
    } = get(general, "SetData.Settings.Feeding.WST", {});
    const {
        DefaultParturitionCurveDay: DefaultParturitionCurveDayNRF = 1,
        DefaultParturitionCurveNumber: DefaultParturitionCurveNumberNRF = 0
    } = get(general, "SetData.Settings.Feeding.NRF", {});
    //wst moze byc tylko indv albo ins bez laczenia
    const curveWST = curves.find(c =>
        [CurveType.PARTURITION, CurveType.INDIVIDUAL].includes(c.SetData.Type)
        && (c.SetData.Index === (DefaultParturitionCurveNumberWST - 1))
        && (getCurveDayShowingType(c) !== CurveDayShowingType.BOTH)
    );
    const dayWST = Math.min(get(curveWST, "SetData.Days.length", 1), DefaultParturitionCurveDayWST);
    //nrf moze byc laczona ale wtedy ustawiany dzien to bedzie dzien inseminacji
    const curveNRF = curves.find(c =>
        [CurveType.PARTURITION, CurveType.INDIVIDUAL].includes(c.SetData.Type)
        && (c.SetData.Index === (DefaultParturitionCurveNumberNRF - 1))
    );
    const dayNRF =
        curveNRF ?
            Math.min((getCurveDayShowingType(curveNRF) === CurveDayShowingType.BOTH ?
                get(curveNRF, "SetData.InseminationJumpTo", 1) : DefaultParturitionCurveDayNRF),
                get(curveNRF, "SetData.Days.length", 1))
            : 1;
    return {
        WST: {
            curve: curveWST,
            day: dayWST
        },
        NRF: {
            simulateButton: DefaultParturitionCurveNumberNRF === -1,
            curve: curveNRF,
            day: dayNRF
        }
    };
});

export const onReportInseminationData = createSelector([getFeedingCurves, getOnReportAction], (curves, general) => {
    const {
        DefaultInseminationCurveDay: DefaultInseminationCurveDayWST = 1,
        DefaultInseminationCurveNumber: DefaultInseminationCurveNumberWST = 0
    } = get(general, "SetData.Settings.Feeding.WST", {});
    const {
        DefaultInseminationCurveDay: DefaultInseminationCurveDayNRF = 1,
        DefaultInseminationCurveNumber: DefaultInseminationCurveNumberNRF = 0
    } = get(general, "SetData.Settings.Feeding.NRF", {});
    //wst moze byc tylko indv albo ins bez laczenia
    const curveWST = curves.find(c =>
        [CurveType.MATING, CurveType.INDIVIDUAL].includes(c.SetData.Type)
        && (c.SetData.Index === (DefaultInseminationCurveNumberWST - 1))
        && (getCurveDayShowingType(c) !== CurveDayShowingType.BOTH)
    );
    const dayWST = Math.min(get(curveWST, "SetData.Days.length", 1), DefaultInseminationCurveDayWST);
    //nrf moze byc laczona ale wtedy ustawiany dzien to bedzie dzien inseminacji
    const curveNRF = curves.find(c =>
        [CurveType.MATING, CurveType.INDIVIDUAL].includes(c.SetData.Type)
        && (c.SetData.Index === (DefaultInseminationCurveNumberNRF - 1))
    );
    const dayNRF =
        curveNRF ?
            Math.min((getCurveDayShowingType(curveNRF) === CurveDayShowingType.BOTH ?
                get(curveNRF, "SetData.InseminationJumpTo", 1) : DefaultInseminationCurveDayNRF),
                get(curveNRF, "SetData.Days.length", 1))
            : 1;
    return {
        WST: {
            curve: curveWST,
            day: dayWST
        },
        NRF: {
            simulateButton: DefaultInseminationCurveNumberNRF === -1,
            curve: curveNRF,
            day: dayNRF
        }
    };
});

export const isLoadingFeedingForPig = createSelector(getFeedingForPigLoadingByAnmID, loadingData => {
    for (let key in loadingData) {
        if (loadingData[key]) return true;
    }
    return false;
});

export const getFeedingSettingMap = createSelector(getFeedingForages, getFeedingCurves, getFeedingSchedules, (forages, curves, schedules) => {
    const makeMap = (settingList) => new Map([...settingList.map((o) => [o.SetID, o])]);
    return {
        curveMap: makeMap(curves),
        forageMap: makeMap(forages),
        scheduleMap: makeMap(schedules)
    };
});


const isDispenserArrayEqual = (() => {
    const isPartiallyEqual = makeIsEqualArrayByItemProperty("DevID", "PlcmntID", 'Settings.Water.Enabled');
    return (...args) => isPartiallyEqual(args[0][0], args[1][0]);
})();

const getMemoizedDispensers = memoizeOne((dispensers) => {
    return dispensers;
}, isDispenserArrayEqual);

const getDispensers = createSelector(utilSelectors.getDevices, (devices) => {
    const dispensers = devices.filter((device) => {
        if (device.DevType === DevTypes.DISPENSER) return true;
        return !!(device.DevType === DevTypes.DISPENSER_NRF && isInFeedingTab(device));
    });
    return getMemoizedDispensers(dispensers);
});

export const getFeedingChamberData = createSelector(getBuildings, getActiveAnimals, getDispensers, roleSelector, (buildings, animals, dispensers, role) => {
    const result = {};
    const standingToChamberDict = {};
    console.log("[recalculating] - feeding chamber data -");
    buildings.map((building) => {
        const { BName, BgID } = building;
        get(building, "Sectors", []).forEach(sector => {
            const { SType } = sector;
            get(sector, "Chambers", []).forEach(chamber => {
                const { IndividualFeeding, CID } = chamber;
                if (result[CID]) {
                    console.error("2 chambers with same Id");
                } else {
                    result[CID] = {
                        chamber: chamber,
                        sectorType: SType,
                        dispensers: [],
                        animals: [],
                        buildingName: BName,
                        buildingId: BgID

                    };
                }
                if (IndividualFeeding) {
                    get(chamber, "Boxes", []).forEach(box => {
                        standingToChamberDict[box.BID] = CID;
                    });
                } else {
                    standingToChamberDict[CID] = CID;
                }
            });
        });
    });
    const plcmntIds = [...Object.keys(standingToChamberDict), ...Object.values(standingToChamberDict)];

    const isStandingOrChamber = memoize((placementId) => plcmntIds.includes(placementId));
    dispensers.forEach((device) => {
        if (isArray(device.PlcmntID)) {
            device.PlcmntID.forEach(p => {
                if (isStandingOrChamber(p.PlcmntID)) {
                    if (!~(result[standingToChamberDict[p.PlcmntID] || p.PlcmntID].dispensers).findIndex(o => o.DevID === device.DevID)) {
                        result[standingToChamberDict[p.PlcmntID] || p.PlcmntID].dispensers.push(device);
                    }

                }
            });
        } else if (isString(device.PlcmntID)) {
            if (isStandingOrChamber(device.PlcmntID)) {
                if (!~(result[standingToChamberDict[device.PlcmntID] || device.PlcmntID].dispensers).findIndex(o => o.DevID === device.DevID)) {
                    result[standingToChamberDict[device.PlcmntID] || device.PlcmntID].dispensers.push(device);
                }

            }
        }
    });
    animals.forEach(animal => {
        if (isArray(animal.PlcmntID)) {
            animal.PlcmntID.forEach(p => {
                if (isStandingOrChamber(p.PlcmntID)) {
                    if (!~(result[standingToChamberDict[p.PlcmntID] || p.PlcmntID].animals).findIndex(o => o.AnmID === animal.AnmID)) {
                        result[standingToChamberDict[p.PlcmntID] || p.PlcmntID].animals.push(animal);
                    }

                }
            });
        } else if (isString(animal.PlcmntID)) {
            if (isStandingOrChamber(animal.PlcmntID)) {
                if (!~(result[standingToChamberDict[animal.PlcmntID] || animal.PlcmntID].animals).findIndex(o => o.AnmID === animal.AnmID)) {
                    result[standingToChamberDict[animal.PlcmntID] || animal.PlcmntID].animals.push(animal);
                }

            }
        }
    });
    return Object.values(result)
        .filter(o => {
            if (!role.hasRoleByPlacementID(UserRoles.DEVICE_FEEDING_READ, o.chamber.CID)) return false;
            return !!o.dispensers.length;
        })
        .sort((o1, o2) => o1.chamber.CName.localeCompare(o2.chamber.CName, undefined, {
            numeric: true,
            sensitivity: "base"
        }));
});
