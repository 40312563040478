import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import SummaryTemplate from "./SummaryTemplate";
import { parseRadar } from "../parsers";
import { getMapData, makeGetAlertsData } from "../../../../selectors/farmMapSelector";
import ErrorList from "./ErrorList";
import WarningList from "./WarningList";
import { getAddressString } from "../../../../utils/DevicesUtils";
import GlobalConfig from "./GlobalConfig";

function SiloRadarContent({
    device,
    t,
    index,
    expanded,
    toggleExpand,
    alerts: { errors, isErrorInChildren, warnings, isWarningInChildren },
    data = {}
}) {
    if (!device) return null;
    const classNames = ["map-drawer"];
    if (expanded) classNames.push("expanded");
    const { text, isLive } = parseRadar(data, { showCapacity: true });
    return (
        <div className={classNames.join(" ")}>
            <div className={"toggle-expand"} aria-label={expanded ? t("collapse") : t("animalDocuments.expand")}
                onClick={toggleExpand} />
            <div className={"map-drawer-content"}>
                <div className={"map-drawer-header"}>
                    <div>{t("braintree.addons.FRM_Siloses.0")}</div>
                    <div>{getAddressString(device, index)}</div>
                </div>
                <ErrorList t={t} errors={errors} isErrorInChildren={isErrorInChildren} />
                <WarningList t={t} warnings={warnings} isWarningInChildren={isWarningInChildren} />
                <div className={"map-drawer-params"}
                    aria-label={t("shadowDescription.CL_SK3.ambientConditions._title")}>
                    <SummaryTemplate icon={"fas fa-wheat"} header={t("forageAmount")} alerts={data.Alerts}
                        warnings={data.Warnings}
                        content={text} isWarning={GlobalConfig.SHOW_OUTDATED_AS_WARNING ? !isLive : false} />
                </div>
            </div>

        </div>
    );
}


const emptyObject = {};

const makeMapStateToProps = () => {
    return (state, props) => {
        const getAlertsData = makeGetAlertsData();
        return {
            data: getMapData(state)[`${props.device.DevID}`] || emptyObject,
            alerts: getAlertsData(state, props.selectedId, "devices")
        };
    };
}

export default compose(
    withTranslation(),
    connect(makeMapStateToProps)
)(SiloRadarContent)
