import moment from "moment";
import React from "react";

function GatewayResponse({response, name}) {
    const {requestedAt, responseTime, type} = response;

    return (
        <div className={"me-2 d-inline-block"}>
            <small className={"me-1"}>{name} ({moment(requestedAt).format(moment.HTML5_FMT.TIME_SECONDS)}):</small>
            {type === "success" && `${responseTime}ms`}
            {type === "error" && <i className={"fas ms-1 fa-times"}/>}
            {type === "loading" && <i className={"fas ms-1 fa-spin fa-circle-notch"}/>}
        </div>
    )
}

export default GatewayResponse;