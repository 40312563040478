import lokiDB from "./lokiDB";
import {
    getModificationTime,
    insertInto,
    setModificationTime,
} from "../utils/LokiUtils";

class Groups {
    insertIntoGroups(values) {
        values.sort((e1, e2) => e1.DtaModTime - e2.DtaModTime);
        insertInto(values, lokiDB.groups, "AnmGrp");
        setModificationTime(
            "groups",
            values[values.length - 1].DtaModTime,
            "FarmID",
            values[values.length - 1].FarmID
        );
    }

    getModificationTime(id) {
        return getModificationTime("groups", "FarmID", id);
    }

    /**
     * Metoda prywatna do pobierania i castowania danych
     * @param object
     * @return {Array<Group>}
     * @private
     */
    _findGroups(object = {}) {
        if (lokiDB.groups) return lokiDB.groups.find(object);
        return null;
    }

    /**
     * Metoda prywatna do pobierania i castowania danych
     * @param object
     * @return {null|Group}
     * @private
     */
    _findOneGroup(object = {}) {
        if (lokiDB.groups) {
            let group = lokiDB.groups.findOne(object);
            if (group) return group;
        }
        return null;
    }

    /**
     * Metoda zwraca grupę po ID
     * @param AnmGrp {string}    ID grupy
     * @param showDeleted
     * @return {Group|null}
     */
    findGroupByID(AnmGrp, {showDeleted = false} = {}) {
        return this._findOneGroup({
            AnmGrp,
            ...(!showDeleted && {DtaDltTime: {$type: "undefined"}}),
        });
    }

    /**
     * Metoda zwraca listę grup
     * @param FarmID {string}   ID fermy
     * @param showDeleted
     * @return {Array<Group>}
     */
    getAllGroups(FarmID, {showDeleted = false} = {}) {
        return this._findGroups({
            FarmID,
            ...(!showDeleted && {DtaDltTime: {$type: "undefined"}}),
        });
    }

    /**
     * Metoda zwracająca wszystkie grupy, do których należy zwierzę
     * @param AnmID {string}    ID zwierzęcia
     * @param FarmID {string}   ID fermy
     * @return {Array<Group>}
     */
    getGroupWithAnimal(AnmID, FarmID) {
        return this._findGroups({
            FarmID,
            AnmIDs: {
                $contains: AnmID,
            },
            DtaDltTime: {$type: "undefined"},
        });
    }

    /**
     * Metoda zwracające grupy grupy o danym numerze
     * @param GrNo1 {string}    Numer grupy
     * @param FarmID {string}   ID fermy
     * @return {Array<Group>}
     */
    getGroupsByGrNo1(GrNo1, FarmID) {
        if (!(GrNo1 && FarmID)) return [];
        let findObj = {
            GrNo1: GrNo1,
            FarmID: FarmID,
        };
        return this._findGroups(findObj);
    }

    /**
     * Sprawdza czy grupa istnieje na fermie
     * @param GrNo1 {string}        Numer grupy
     * @param FarmID {string}       ID fermy
     * @return {boolean}
     */
    checkIfGroupExistsOnFarm(GrNo1, FarmID) {
        return this.getGroupsByGrNo1(GrNo1, FarmID).length > 0;
    }

    getGroupWithRemovedAnimal(AnmID, FarmID) {
        return this._findGroups({
            FarmID,
            Rmvd: {
                $contains: AnmID,
            },
        });
    }

    getGroupThatContainsAnmID(AnmID, FarmID) {
        return this._findGroups({
            FarmID,
            $or: [{Rmvd: {$contains: AnmID}}, {AnmIDs: {$contains: AnmID}}],
        });
    }

    getGroupByAnmGrp(AnmGrp, FarmID) {
        return this._findOneGroup({AnmGrp, FarmID});
    }

    removeTemporaryGroup(AnmGrp) {
        lokiDB.groups.chain().find({AnmGrp, isTemporary: true}).remove();
    }
}

const groupsDB = new Groups();
export default groupsDB;
