import {writeFile} from "xlsx";
import Workbook from "./WorkBook";
import moment from "moment";
import {get, isArray} from "lodash";

export default class XLSX {
    constructor(
        data,
        options = {
            headers: null,
            sheetName: null,
            addDefaultDate: true,
        }
    ) {
        this.workbook = new Workbook();
        this.addDefaultDate = options.hasOwnProperty("addDefaultDate")
            ? options.addDefaultDate
            : true;
        let sheet;
        if (options.headers) {
            let headersMapped = options.headers.map((header) => header.name);
            sheet = this.workbook.addSheet(options.sheetName, [headersMapped]);
        }
        if (!sheet) {
            this.workbook.addSheet(options.sheetName, data);
        } else {
            sheet.addData(data, "A2");
        }
    }

    save(fileName) {
        let _fileName = `${fileName}`;
        if (this.addDefaultDate)
            _fileName += `_${moment().format("YYYY_MM_DD_HH_mm_ss")}`;
        writeFile(this.workbook, `${_fileName}.xlsx`);
    }

    static formatData(headers, data) {
        return data
            .filter((row) => !row._excelSkip)
            .map((row) => {
                return headers.map((header) => {
                    const value = get(row, header.field);
                    if (row.isFullWidth) return value || "";
                    if (header.excelFormatter)
                        return header.excelFormatter(
                            header.field ? value : row
                        );
                    if (isArray(value)) return value.join(", ");
                    return value;
                });
            });
    }
}
