import {withTranslation} from "react-i18next";
import SummaryTemplate from "./SummaryTemplate";
import React from "react";
import {parseWater} from "../parsers";
import {makeExtractKeys} from "../../../../utils/FarmMapUtils";
import GlobalConfig from "./GlobalConfig";

const getKeys = makeExtractKeys();

function WaterSummary({devices, data, t}) {
    const result = [];
    const keys = getKeys(devices);
    for (let key of keys) {
        const {isLive, text} = parseWater(data[key])
        result.push(<SummaryTemplate icon={"fas fa-wheat"} header={t("charts.waterHourlyChart.consumption")}
                                     alerts={data[key]?.Alerts}
                                     warnings={data[key]?.Warnings}
                                     isWarning={GlobalConfig.SHOW_OUTDATED_AS_WARNING ? !isLive : false} content={text}/>)
    }
    return result;
}

export default withTranslation()(WaterSummary);