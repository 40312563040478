import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getMapData, makeGetAlertsData} from "../../../../selectors/farmMapSelector";
import ErrorList from "./ErrorList";
import WarningList from "./WarningList";
import {getAddressString} from "../../../../utils/DevicesUtils";
import SummaryTemplate from "./SummaryTemplate";
import {isArray, isFunction, isString} from "lodash";
import DeviceControlPanel from "../device-controls/DeviceControlPanel";
import GlobalConfig from "./GlobalConfig";


const emptyObject = {};

const DeviceContentTemplate = ({
                                   device,
                                   expanded,
                                   toggleExpand,
                                   selectedId,
                                   name,
                                   parseDataFn,
                                   paramLabel,
                                   paramKeys,
                                   enableSpecificControls,
                                   showAddr = true,
                                   transformErrors
                               }) => {
    const {t} = useTranslation();
    const getAlertsData = useMemo(() => makeGetAlertsData(), []);
    const params = useMemo(() => {
        if (!paramKeys && isString(paramLabel)) return [{label: paramLabel, key: "text"}];
        if (!isArray(paramKeys) || !isArray(paramLabel)) return [];
        if (paramKeys.length !== paramLabel.length) return [];
        return paramKeys.map((key, i) => ({key, label: paramLabel[i]}));
    }, [paramKeys, paramLabel])
    const errorState = useSelector((state) => getAlertsData(state, selectedId, "devices"));
    
    const {errors, isErrorInChildren, warnings, isWarningInChildren} =
        useMemo(() => {
            if (transformErrors) return transformErrors(errorState);
            return errorState;
        }, [errorState, transformErrors]);
    
    const data = useSelector((state) => getMapData(state)[device?.DevID ?? "null"] || emptyObject);
    const parsed = useMemo(() => {
        if (!isFunction(parseDataFn)) {
            return null;
        }
        return parseDataFn(data);
    }, [parseDataFn, data]);
    if (!device) return null;
    const classNames = ["map-drawer"];
    if (expanded) classNames.push("expanded");
    return (
        <div className={classNames.join(" ")}>
            <div className={"toggle-expand"} aria-label={expanded ? t("collapse") : t("animalDocuments.expand")}
                 onClick={toggleExpand}/>
            <div className={"map-drawer-content"}>
                <div className={"map-drawer-header"}>
                    <div>{name}</div>
                    {
                        !!showAddr &&
                        <div>{getAddressString(device)}</div>
                    }
                </div>
                {
                    !!enableSpecificControls &&
                    <DeviceControlPanel enableSpecificControls={enableSpecificControls} placementId={device.PlcmntID}
                                        devices={[{device}]}/>
                }
                <ErrorList t={t} errors={errors} isErrorInChildren={isErrorInChildren}/>
                <WarningList t={t} warnings={warnings} isWarningInChildren={isWarningInChildren}/>
                {
                    !!parsed &&
                    <div className={"map-drawer-params"}
                         aria-label={t("shadowDescription.CL_SK3.ambientConditions._title")}>
                        {
                            params.map(({key, label}) => (
                                <SummaryTemplate key={key} icon={"fas fa-wheat"} header={label} alerts={data.Alerts}
                                                 warnings={data.Warnings}
                                                 content={parsed[key] ?? "-"} isWarning={GlobalConfig.SHOW_OUTDATED_AS_WARNING ? !parsed.isLive : false}/>
                            ))
                        }
                    </div>
                }
            </div>
        </div>
    );
}


export default React.memo(DeviceContentTemplate);