import {safeMultiply, safeSubtract} from "./Utils";
import {isFinite, isNumber, unary, clamp} from "lodash";

export const getPercentage = (value, min, max, {clampValue = true} = {}) => {
    if (clampValue) {
        value = clamp(value, min, max);
    }
    return 100 * (value - min) / (max - min);
}

export const getValueInBetweenRangeByPercentage = (startValue, endValue, percentage) => {
    if (endValue === startValue) return startValue;
    return startValue + ((endValue - startValue) * percentage / 100)
}

export const getValueInBetweenRangeByMinAndMax = (startValue, endValue, min, max) => {
    return getValueInBetweenRangeByPercentage(startValue, endValue, (max === 0 && min === 0) ? 100 : (min / max) * 100);
}

/**
 * zwraca wartość 0...1 dla `currentX` mówiąca, w jakim miejscu znajduje się między punktami `x1` - `x2`\
 * jeśli `currentX` znajduje się poza odcinkiem `x1-x2` zwraca 0 lub 1
 * @param currentX
 * @param x1
 * @param x2
 * @return {number}
 */
export const getNormalizedDistance = (currentX, x1, x2) => {
    const descending = x1 > x2;
    // punkt znajduje się na odcinku
    if (isBetween(currentX, x1, x2)) {
        const normalized = (currentX - Math.min(x1, x2)) / Math.abs(x1 - x2)
        return descending ? 1 - normalized : normalized;
        // punkt znajduje się poza odcinkiem
    } else if (descending) {
        return +!(currentX > x1);
    }
    return +(currentX > x2);
}


/**
 * zwraca najbliższy step jest javascripotowo-idioto odporne na zaokgraglanie floatów
 * @param value
 * @param min
 * @param max
 * @param step
 * @returns {number}
 */
export const getClosestValueByMinMaxAndStep = (value, min = 0, max = 100, step = 1) => {
    const start = Math.min(Math.max(min, value), max)
    const diff = safeSubtract(start, min);
    const stepsToDiff = Math.round(diff / step);
    return (min + safeMultiply(step, stepsToDiff));
}


export const getRandomValueInRange = (min, max, step = 1) => {
    const rand = Math.random();
    const value = getClosestValueByMinMaxAndStep((max - min) * rand + min, min, max, step);
    console.log(min, max, value)
    return value;
}

/**
 *
 * @param value {number}
 * @param minValue {number}
 * @param maxValue {number}
 * @param inclusivity {string}
 * @returns {boolean}
 */
export const isBetween = (value, minValue, maxValue, {inclusivity = "[]"} = {}) => {
    // swap values if needed
    if (minValue > maxValue) {
        [minValue, maxValue] = [maxValue, minValue];
    }
    if (inclusivity[0] === "[" && value < minValue) return false;
    if (inclusivity[0] === "(" && value <= minValue) return false;
    if (inclusivity[1] === "]" && value > maxValue) return false;
    return !(inclusivity[1] === ")" && value >= maxValue);

}

/**
 * funkcja powstawla poniewaz dla wykrywania zwyklych skonczoncych numerow (isNumber daje true dla infinity a isFinite dla nulla)
 * @param value
 * @return {boolean}
 */
export const isFiniteNumber = (value) => isNumber(value) && isFinite(value)

/**
 *
 * przykładowy obraz:
 0  0  0  0  0  1  1  0  0  0  0  0  0
 0  0  0  0  1  1  1  1  0  0  0  0  0
 0  0  0  1  1  1  1  1  1  0  0  0  0
 0  0  1  1  1  1  1  1  1  1  0  0  0
 0  1  1  1  1  1  1  1  1  1  1  0  0
 1  1  1  1  1  1  1  1  1  1  1  0  0
 0  1  1  1  1  1  1  1  1  1  1  0  0
 0  0  1  1  1  1  1  1  1  1  1  0  0
 0  0  0  1  1  1  1  1  1  1  0  0  0
 0  0  0  0  1  1  1  1  1  1  0  0  0
 0  0  0  0  0  1  1  1  1  0  0  0  0
 0  0  0  0  0  0  0  0  0  0  0  0  0
 0  0  0  0  0  0  0  0  0  0  0  0  0
 0  0  0  0  0  0  0  0  0  0  0  0  0

 tworzymy dla niego histogram i liczmy najwiekszy prostokat w tym samym loopie co tworzymy zeby szybciej bylo
 0  0  0  0  0  1  1  0  0  0  0  0  0
 0  0  0  0  1  2  2  1  0  0  0  0  0
 0  0  0  1  2  3  3  2  1  0  0  0  0
 0  0  1  2  3  4  4  3  2  1  0  0  0
 0  1  2  3  4  5  5  4  3  2  1  0  0
 1  2  3  4  5  6  6  5  4  3  2  0  0
 0  3  4  5  6  7  7  6  5  4  3  0  0
 0  0  5  6  7  8  8  7  6  5  4  0  0
 0  0  0  7  8  9  9  8  7  6  0  0  0
 0  0  0  0  9 10 10  9  8  7  0  0  0
 0  0  0  0  0 11 11 10  9  0  0  0  0
 0  0  0  0  0  0  0  0  0  0  0  0  0
 0  0  0  0  0  0  0  0  0  0  0  0  0
 0  0  0  0  0  0  0  0  0  0  0  0  0
 * @param getValueAt pobieranie wartosi pixela dla argumentow X,Y
 * @param width szerokos obrazu
 * @param height wysokos obrazu
 * @returns {{area: number, y1: number, x1: number, y2: number, x2: number}}
 */
export const getBiggestRectInMatrix = (getValueAt, width, height) => {
    const result = {area: 0, x1: 0, x2: 0, y1: 0, y2: 0};
    // histogram z wysokosciami dla danego X
    const depth = new Array(width).fill(0);
    const setArea = (_x, _y, _depth, _ranges) => {
        const h = Math.max(0, _depth[_ranges.pop()]);
        const w = Math.max(0, _ranges.length ? _x - _ranges[_ranges.length - 1] : _x)
        const area = h * w;
        if (area > result.area) {
            // values are shifted by 1px dunno why - lets just adjust them here
            const xVals = [_x - 1, _x - w + 2]
            const yVals = [_y, _y - h + 1]
            result.area = area;
            result.x2 = Math.max(...xVals);
            result.x1 = Math.min(...xVals);
            result.y1 = Math.min(...yVals);
            result.y2 = Math.max(...yVals);
        }
    }
    for (let y = 0; y < height; y++) {
        const ranges = [];
        for (let x = 0; x < width; x++) {
            // jesli mamy wartosc to dodajemy do histogramu jesli nie to ustawiamy na 0
            if (getValueAt(x, y)) {
                depth[x] += 1;
                // ranges.push(x);
            } else {
                depth[x] = 0;
            }
            // liczymy wielkosc dla histogramu
            while (ranges.length && depth[x] <= depth[ranges[ranges.length - 1]]) {
                setArea(x, y, depth, ranges);
            }
            ranges.push(x);
        }
        while (ranges.length) {
            setArea(width, y, depth, ranges);
        }
    }
    return result;
}

/**
 *
 * @param data {array} tablica obiektów z kluczami w (weight - liczba calkowita) oraz v (value)
 */
export const getWeightedRandomNumber = (data = []) => {
    const sum = data.reduce((a, b) => a + b.w, 0);
    let random = Math.round(Math.random() * sum);
    for (let i = 0; i < data.length; ++i) {
        if (random <= data[i].w) {
            return data[i].v;
        }
        random -= data[i].w;
    }
    return null;
}


export const multiplyMatrices = (mat1, mat2) => {
    const result = [];
    for (let i = 0; i < mat1.length; i++) {
        result[i] = [];
        for (let j = 0; j < mat2[0].length; j++) {
            let sum = 0;
            for (let k = 0; k < mat1[0].length; k++) {
                sum += mat1[i][k] * mat2[k][j];
            }
            result[i][j] = sum;
        }
    }
    return result;
}


export const rotateCartesian = (x, y, angle) => {
    const rad = angle / (180 / Math.PI);
    return {
        x: Math.cos(rad) * x - Math.sin(rad) * y,
        y: Math.sin(rad) * x + Math.cos(rad) * y
    }
}

export const checkSign = (...numbers) => {
    let sign = null;
    for (let num of numbers) {
        if (sign === null) {
            sign = +(num > 0);
        }
        if (sign !== +(num > 0)) {
            return false;
        }
    }
    return true;
}


export const createGaussFunction = ([a, b, c]) => {
    return (x) => a * Math.exp(-Math.pow(x - b, 2) / (2 * Math.pow(c, 2)));
}


export const createLinearFunction = ([m, b]) => {
    return (x) => m * x + b;
}

export const getLinearFunctionByTwoPoints = (x1, y1, x2, y2, {response = "function"} = {}) => {
    const m = (y2 - y1) / (x2 - x1);
    const b = y1 - (m * x1);
    return response === "params" ? [m, b] : createLinearFunction([m, b]);
}

/**
 *
 * @param index {number} - single dimension array index
 * @param arraySize {array<number>} - size of each dimension
 * @return {array} - index for each dimension
 */
export const castSingleIndexToMultiDimensionArrayIndex = (index, arraySize) => {
    if (!arraySize || arraySize.length === 0) return [index];
    let tmp = index;
    const newArray = [];
    for (let i = 0; i < arraySize.length; i++) {
        const offset = arraySize.slice(1 + i).reduce((a, b) => a * b, 1);
        newArray[i] = Math.floor(tmp / offset);
        tmp -= offset * newArray[i];
    }
    return newArray;
}

/**
 * changes a number with potential exponent i.e: 1.9e-22 to a real number
 * @param num {number}
 * @param fixed {null|number} - precision
 * @return {string}
 */
export const eToNumber = (num, fixed = null) => {
    let [data, exp] = String(num).split(/[eE]/);
    const toFixed = (str) => {
        if (isFiniteNumber(fixed)) {
            const p = str.indexOf(".");
            if (p !== -1) {
                str = str.substring(0, p + fixed); // remove extra characters (similar to toFixed but with no rounding)
                str = str.replace(/\.0+$/, ""); // removes zeros
            }
            return str;
        }
        return str;
    }
    if (!exp) return toFixed(data);
    let z = "";
    const sign = num < 0 ? "-" : "";
    // remove sign and dot
    const s = data.replace(".", "").replace(/^-/, "");
    let m = Number(exp) + 1;
    let result;
    if (m < 0) {
        z = `${sign}0.`;
        while (m++) {
            z += "0";
        }
        result = `${z}${s}`;
    } else {
        m -= s.length;
        while (m--) {
            z += "0";
        }
        result = `${sign}${s}${z}`;
    }
    return toFixed(result);

};

// poprawiamy liczby typu 0.00021080000000000003
export function fixFloatingPrecision(possiblyNumber, precision = 10) {
    if (!isFiniteNumber(possiblyNumber)) return possiblyNumber;
    return +possiblyNumber.toFixed(precision);
}

export const interp = {
    linear: ({x, x1, y1, x2, y2} = {}) => {
        if (x1 === x2) {
            return (y1 + y2) / 2
        }
        return ((x - x1) * (y2 - y1)) / (x2 - x1) + y1
    }
}

export function calculateStandardDeviation(data) {
    const average = data.reduce((prev, curr) => prev + curr, 0) / data.length;
    const variance = data.reduce((prev, curr) => prev + Math.pow(curr - average, 2), 0) / data.length;
    return {deviation: +Math.sqrt(variance).toFixed(2), average};
}

export function getWeightedAverage(nums, weights) {
    const [sum, weightSum] = weights.reduce((acc, w, i) => {
        acc[0] = acc[0] + nums[i] * w;
        acc[1] = acc[1] + w;
        return acc;
    }, [0, 0]);
    return sum / weightSum;
}

export function round(number, {
    increment = 1,
    offset = 0,
    type = "floor"
} = {}) {
    return Math[type]((number - offset) / increment) * increment + offset;
}

export function booleanLikeArrayToInteger(array) {
    let number = 0;
    array.forEach((value, index) => {
        number |= ((+!!value) << index)
    });
    return number;
}

export function integerToBooleanArray(integer, fixedArraySize) {
    const array = (integer).toString(2).split("").reverse().map(unary(Number));
    console.log(integer);
    if (fixedArraySize) {
        array.length = fixedArraySize;
    }
    for (let i = 0; i < array.length; i++) {
        array[i] = !!array[i];
    }
    console.log("toArr=%s", JSON.stringify(array));
    return array;
}

export function getRandomNumber(min = 0, max = 100) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function easeOutCirc(x) {
    const c4 = (2 * Math.PI) / 3;

    return x === 0
        ? 0
        : x === 1
            ? 1
            : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
}