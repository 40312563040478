import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import SummaryTemplate from "./SummaryTemplate";
import { parseGroup } from "../parsers";
import { getMapData, makeGetAlertsData } from "../../../../selectors/farmMapSelector";
import ErrorList from "./ErrorList";
import WarningList from "./WarningList";
import DeviceControlPanel from "../device-controls/DeviceControlPanel";
import { getShortInterfaceWithAddress } from "../../../../utils/DevicesUtils";
import GlobalConfig from "./GlobalConfig";

function GroupContent({
    device,
    t,
    expanded,
    toggleExpand,
    location,
    alerts: { errors, isErrorInChildren, warnings, isWarningInChildren },
    data = {}
}) {
    if (!device) return null;
    const classNames = ["map-drawer"];
    if (expanded) classNames.push("expanded");
    const { text, isLive } = parseGroup(data);
    return (
        <div className={classNames.join(" ")}>
            <div className={"toggle-expand"} aria-label={expanded ? t("collapse") : t("animalDocuments.expand")}
                onClick={toggleExpand} />
            <div className={"map-drawer-content"}>
                <div className={"map-drawer-header"}>
                    <div>{t("deviceRows.feeding.selfblock.title")}</div>
                    <div>{`(${getShortInterfaceWithAddress(device)})`}</div>
                </div>
                {
                    !!device &&
                    <DeviceControlPanel placementId={location.id} devices={[{ device }]} />
                }
                <ErrorList t={t} errors={errors} isErrorInChildren={isErrorInChildren} />
                <WarningList t={t} warnings={warnings} isWarningInChildren={isWarningInChildren} />
                <div className={"map-drawer-params"}
                    aria-label={t("shadowDescription.CL_SK3.ambientConditions._title")}>
                    <SummaryTemplate icon={"fas fa-wheat"} header={t("deviceRows.feeding.animalAtTrough")} alerts={data.Alerts}
                        content={text} isWarning={GlobalConfig.SHOW_OUTDATED_AS_WARNING ? !isLive : false} warnings={data.Warnings} />
                </div>
            </div>
        </div>
    );
}


const emptyObject = {};

const makeMapStateToProps = () => {
    return (state, props) => {
        const getAlertsData = makeGetAlertsData();
        return {
            data: getMapData(state)[props.device.DevID] || emptyObject,
            alerts: getAlertsData(state, props.selectedId, "devices")
        };
    };
}

export default compose(
    withTranslation(),
    connect(makeMapStateToProps)
)(GroupContent)
