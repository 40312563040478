import {invokeApig} from "../libs/awsLib";
import Paths from "../api/paths";
import groupsDB from "../database/groupsDB";
import {getAnimalModificationTime} from "./animalsActions";

export function getGroupModificationTime(farmID) {
    return function (dispatch) {
        dispatch({
            type: "GET_GROUP_MODIFICATION_TIME",
            payload: groupsDB.getModificationTime(farmID).DtaModTime,
        });
    };
}

export function listGroupsDynamoDB(farmID, forceFetch = false) {
    return function (dispatch) {
        dispatch({
            type: "LIST_GROUPS_DYNAMO_DB",
            payload: invokeApig({
                ...Paths.listGroups({farmID}),
                queryParams: {
                    DtaModTime: groupsDB.getModificationTime(farmID).DtaModTime,
                },
                forceFetch,
            }),
        })
            .then((res) => {
                if (res.value?.items.length > 0) {
                    groupsDB.insertIntoGroups(res.value.items);
                }
                const changedIDs = res.value.items
                    .map(({AnmID, AnmGrp}) => AnmID || AnmGrp)
                    .filter((item) => item);
                dispatch(
                    getAnimalModificationTime(
                        farmID,
                        changedIDs.length ? changedIDs : null
                    )
                );
                dispatch(getGroupModificationTime(farmID));
            })
            .finally(() => {
                dispatch(getAllGroups(farmID));
            });
    };
}

export function getAllGroups(farmID) {
    return function (dispatch) {
        dispatch({
            type: "GET_ALL_GROUPS",
            payload: groupsDB.getAllGroups(farmID),
        });
    };
}
