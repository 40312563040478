import * as AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import memoizeOne from "memoize-one";
import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { connectModal, show } from "redux-modal";
import { BoarTypes } from "../../../constans/boarTypes";
import { Level } from "../../../constans/levelTypes";
import animalsDB from "../../../database/animalsDB";
import eventsDB from "../../../database/eventsDB";
import { getTattooedGiltsWithRFID } from "../../../selectors/animalSelector";
import { makeGetManageBuildingsList } from "../../../selectors/buildingsSelector";
import { getBoarOptions, getRacesOptions, raceFormatter } from "../../../utils/DataGridUtils";
import { normalizeRFID } from "../../../utils/NormalizeFunctions";
import Button from "../../basics/button/Button";
import InfoBox from "../../basics/info-box/InfoBox";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import Tooltip from "../../basics/tooltip/Tooltip";
import ConfirmModificationField from "../../custom-fields/ConfirmModificationField";
import { ModalName as ConfirmRemoveAnimalModalName } from "../confirm-remove-animal/ConfirmRemoveAnimal";
import { ModalName as ConfirmResurrectAnimalModalName } from "../confirm-resurect-animal/ConfirmResurrectAnimal";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import ModalHeader from "../ModalHeader";
import { submit, validate, warn } from "./EditAnimalBasicsModalSubmit";
import { getValidationSettings } from "../../../utils/SettingsUtils";
import { checkIfUserIsService } from "../../../utils/NewRolesUtils";

export const ModalName = "edit-basics-modal";

const selector = formValueSelector(ModalName);

function makeMapStateToProps() {
    const getManageBuildingsList = makeGetManageBuildingsList();
    const getBoxesFromBuildingList = memoizeOne((buildings) =>
        buildings.filter(({ level }) => level === Level.BOX)
    );
    return function mapStateToProps(state) {
        return {
            race: state.dictionary.race.WData,
            suppliers: state.dictionary.clients.WData,
            DtaBrthTime: selector(state, "DtaBrthTime"),
            farm: state.location.farm,
            boxesList: getBoxesFromBuildingList(getManageBuildingsList(state)),
            giltsWithRFID: getTattooedGiltsWithRFID(state),
            validations: getValidationSettings(state)
        };
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({ show }, dispatch),
    };
}

export class EditAnimalBasicsModal extends React.Component {
    constructor(props) {
        super(props);
        const { animal, initialize, geneticsInformation } = this.props;
        const fields = {
            ...animal,
            AnmNo2: animal.AnmNo2 || geneticsInformation?.TattooNumber,
            MotherNumber: geneticsInformation?.MotherAnmNo1,
            MotherTattoo: geneticsInformation?.MotherTattoo,
            MotherRace: geneticsInformation?.MotherRaceValue,
            FatherNumber: geneticsInformation?.FatherAnmNo1,
            FatherTattoo: geneticsInformation?.FatherTattoo,
            FatherRace: geneticsInformation?.FatherRaceValue,
        };
        if (this.isBoar()) {
            fields.BoarType = animal.BoarType || BoarTypes.STATIONARY;
        }
        initialize(fields);
    }

    isSowOrBoar = () => {
        const {
            animal: { AnimalKind },
        } = this.props;
        return [
            AnimalTypes.RENOVATION_SOW,
            AnimalTypes.SOW,
            AnimalTypes.BOAR,
        ].includes(AnimalKind);
    };

    isBoar = () => {
        const { animal: { AnimalKind } } = this.props;
        return AnimalKind === AnimalTypes.BOAR;
    };

    onRemoveAnimalClick = () => {
        const { show, handleHide, animal } = this.props;
        show(ConfirmRemoveAnimalModalName, { animal });
        handleHide();
    };

    onResurrectClick = () => {
        const { show, handleHide, animal } = this.props;
        show(ConfirmResurrectAnimalModalName, { animal });
        handleHide();
    };

    checkIfShouldBeDisabled = () => {
        const { animal, farm, boxesList, giltsWithRFID } = this.props;
        if (animal) {
            const animalOnFarm = animalsDB.getAnimalByAnmNo1(animal.AnmNo1, farm);
            if (!!animalOnFarm) return true;
            if (animal.RFID) {
                if (animal.RFID.length > 15) return true;
                // znajdz tylko aktualnie zywe wiec jesli jakies jest to znaczy ze nie mozna przywrocic zwierzecia
                const animalByRFID = animalsDB.getAnimalByRfid(animal.RFID, farm) || giltsWithRFID[animal.RFID];
                if (!!animalByRFID) return true;
                if (animalsDB.checkIfHaveRFID(animal.RFID, farm)) {
                    if (!animalByRFID.AnmNo1.includes(animalByRFID.RFID.slice(-4))) return true;
                }
                if (boxesList.length > 0) {
                    const existingBox = boxesList.find((box) => box.rfid === animal.RFID);
                    if (!!existingBox) return true;
                }
            }
        }
        return false;
    };

    checkIfHasEconomicSaleAssigned = () => {
        const { animal } = this.props;
        const sell = eventsDB.getAllEvents4AnimalWithType(animal.AnmID, EventTypes.SELL)[0];
        if (sell?.EvData?.SaleID) return true;
        return false;
    };

    checkIfRemoveShouldBeDisabled = () => {
        const {events} = this.props;
        if(checkIfUserIsService()) return false
        const AllowedEventTypes = [EventTypes.INSERTION, EventTypes.TRANSFER, EventTypes.SOW_CYCLES]
        // #11098
        // const removeAnimalWithEvents = get(validations,'removeWithEvents',ValidationDefaultValues.removeAnimalWithEvents)
        // if(!removeAnimalWithEvents) return false
        for (let event of events){
            if(!AllowedEventTypes.includes(event.EvCode)){
                return true
            }
        }
        return false;
    }
    renderGeneral() {
        const { animal, t, suppliers, DtaBrthTime } = this.props;
        return (
            <>
                <Field
                    component={ReduxLabeledInput}
                    type="text"
                    name="AnmNo1"
                    label={t("animalNumber")}
                />
                <Field
                    component={ReduxLabeledInput}
                    type="text"
                    name="HerdNumber"
                    label={t("herdNumber")}
                />
                {(!Array.isArray(animal.PlcmntID) ||
                    animal.PlcmntID.length === 1) && (
                        <>
                            {!this.isSowOrBoar() && (
                                <Field
                                    name="AnmCnt"
                                    component={ReduxLabeledInput}
                                    label={t("animalCount")}
                                    type={"number"}
                                    parse={(value) =>
                                        value !== "" ? +value : null
                                    }
                                />
                            )}
                        </>
                    )}
                {
                    <Field
                        component={ReduxLabeledInput}
                        name="RFID"
                        label={t("rfid")}
                        type="number"
                        normalize={normalizeRFID}
                    />
                }
                <Field
                    component={ReduxLabeledSelect}
                    name="SupplierID"
                    label={t("supplier")}
                    options={suppliers.map((reason) => ({
                        name: reason.Value,
                        value: reason.ID,
                    }))}
                />
                {
                    this.isBoar() &&
                    <Field
                        component={ReduxLabeledSelect}
                        name="BoarType"
                        label={t("type")}
                        options={getBoarOptions()}
                    />
                }
                {!DtaBrthTime && (
                    <InfoBox boxColor={"warning"}>{`${t("birthDate")} - ${t(
                        "errors.invalidDate"
                    )}`}</InfoBox>
                )}
                <Field
                    label={t("birthDate")}
                    component={ReduxLabeledInput}
                    name="DtaBrthTime"
                    type={"date"}
                    format={(value) =>
                        value ? moment(value).format("YYYY-MM-DD") : null
                    }
                    parse={(value) =>
                        value ? +moment(value, "YYYY-MM-DD") : null
                    }
                />
                <Field
                    label={t("insertionDate")}
                    component={ReduxLabeledInput}
                    name="DtaInTime"
                    type={"date"}
                    format={(value) =>
                        value ? moment(value).format("YYYY-MM-DD") : null
                    }
                    parse={(value) =>
                        value ? +moment(value, "YYYY-MM-DD") : null
                    }
                />
            </>
        );
    }

    renderGenetics() {
        const { t, race, animal } = this.props;
        const racesOptions = getRacesOptions(race);
        // TODO - w przyszlosci byc moze bedzie mozliwosc zmiany matki/ojca ale trzeba sie zastanowic jak to obsluzyc z racji z mamy powiazania z eventami itd.
        return (
            <>
                <Field
                    name={"AnmNo2"}
                    id={"AnmNo2"}
                    component={ReduxLabeledInput}
                    label={t("tattooNumber")}
                    type={"text"}
                />
                <Field
                    label={t("race")}
                    component={ReduxLabeledSelect}
                    name="Race"
                    options={racesOptions}
                    customRenderer={raceFormatter}
                />
                {
                    ![AnimalTypes.PORKER, AnimalTypes.PIGLET, AnimalTypes.BOAR].includes(animal.AnimalKind) &&
                    (
                        <>
                            <Field
                                name={"MotherNumber"}
                                label={t("motherNumber")}
                                id={"MotherNumber"}
                                component={ReduxLabeledInput}
                                type={"text"}
                            />
                            <Field
                                name={"MotherTattoo"}
                                label={t("motherTattoo")}
                                id={"MotherTattoo"}
                                component={ReduxLabeledInput}
                                type={"text"}
                            />
                            <Field
                                name={"MotherRace"}
                                label={t("motherRace")}
                                id={"MotherRace"}
                                component={ReduxLabeledSelect}
                                options={racesOptions}
                                customRenderer={raceFormatter}
                            />
                            <Field
                                name={"FatherNumber"}
                                label={t("fatherNumber")}
                                id={"FatherNumber"}
                                component={ReduxLabeledInput}
                                type={"text"}
                            />
                            <Field
                                name={"FatherTattoo"}
                                label={t("fatherTattoo")}
                                id={"FatherTattoo"}
                                component={ReduxLabeledInput}
                                type={"text"}
                            />
                            <Field
                                name={"FatherRace"}
                                label={t("fatherRace")}
                                id={"FatherRace"}
                                component={ReduxLabeledSelect}
                                options={racesOptions}
                                customRenderer={raceFormatter}
                            />
                        </>
                    )
                }
            </>
        );
    }

    render() {
        const {
            show,
            handleHide,
            animal,
            t,
            handleSubmit,
            submitting,
            dirty,
            invalid,
            type,
        } = this.props;
        const isDead = animal.DtaDthTime || animal.DtaDelTime;
        const shouldResurrectButtonBeDisabled = this.checkIfShouldBeDisabled();
        const shouldRemoveBeDisabled = this.checkIfRemoveShouldBeDisabled();
        const hasEconomicSaleAssigned = this.checkIfHasEconomicSaleAssigned();
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("modals.editBasicsAnimal.title", {
                            type: animal.AnmNo1,
                        })}
                        onCloseClick={handleHide}
                    />
                    <ModalBody>
                        {(!type || type === "GENERAL") && this.renderGeneral()}
                        {type === "GENETICS" && this.renderGenetics()}
                        <ConfirmModificationField name={animal.AnmNo1} />
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton
                        confirmText={t("save")}
                        hasButtonDisabled={invalid || !dirty}
                        onCloseClick={handleHide}
                        formName={ModalName}
                        submitting={submitting}
                        optionalButtons={[
                            !isDead && !shouldRemoveBeDisabled && (
                                <Button
                                    icon={<i className="fas fa-trash" />}
                                    buttonColor={"error"}
                                    key={1}
                                    onClick={this.onRemoveAnimalClick}
                                    type={"button"}>
                                    {t("removeAnimal")}
                                </Button>
                            ),
                            shouldResurrectButtonBeDisabled && isDead && (
                                <Tooltip
                                    tooltipContent={t(
                                        "modals.editBasicsAnimal.cannotResurrectAnmNo1"
                                    )}
                                    type="error">
                                    <i className="fas fa-exclamation-circle error" />
                                </Tooltip>
                            ),
                            hasEconomicSaleAssigned && isDead && !shouldResurrectButtonBeDisabled && (
                                <Tooltip
                                    tooltipContent={t("cantResurrectFoundSale")}
                                    type="error" >
                                    <i className="fas fa-exclamation-circle error" />
                                </Tooltip>
                            ),
                            isDead && (
                                <Button
                                    icon={<i className="fas fa-redo" />}
                                    buttonColor={"warning"}
                                    key={2}
                                    type={"button"}
                                    disabled={shouldResurrectButtonBeDisabled || hasEconomicSaleAssigned}
                                    onClick={this.onResurrectClick}>
                                    {t("resurrectAnimal")}
                                </Button>
                            ),
                        ]}
                    />
                </form>
            </Modal>
        );
    }
}

export default compose(
    connectModal({ name: ModalName }),
    withTranslation(),
    connect(makeMapStateToProps, mapDispatchToProps),
    reduxForm({
        form: ModalName,
        onSubmit: submit,
        validate,
        warn,
    })
)(EditAnimalBasicsModal);
