import lokiDB from "./lokiDB";
import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import {
    getModificationTime,
    insertInto,
    setModificationTime,
} from "../utils/LokiUtils";
import {
    getAliveAnimalsAmountFromMommyGiltEvents,
    getAliveAnimalsAmountFromTattooEvents,
    getPigBalance,
} from "../utils/EventUtils";
import animalsDB from "./animalsDB";
import {isNil, memoize} from "lodash";
import {convertRowsToCycles, preEvents} from "../utils/AnimalDocumentsUtils";
import moment from "moment";

class Events {
    constructor() {
        this.getAllEvents4Animal = memoize(this.getAllEvents4Animal);
    }

    clearCache() {
        this.getAllEvents4Animal.cache.clear();
    }

    /**
     * Funkcja do pobierania ostatniej daty modyfikacji farmy na danej fermie
     * @param id FarmID
     * @return {{DtaModTime: string}} 0 jeśli nie było wcześniej informacji o ostatniej dacie modyfikacji dla danego farmdID
     */
    getModificationTime(id) {
        return getModificationTime("events", "FarmID", id);
    }

    //insert into animals
    insertIntoEvents(values) {
        insertInto(values, lokiDB.events, "EvID");
        setModificationTime(
            "events",
            values[values.length - 1].DtaModTime,
            "FarmID",
            values[values.length - 1].FarmID
        );
        this.clearCache();
    }

    //get All events for animal
    getAllEvents4Animal(AnmID) {
        try {
            let evts = lokiDB.events.find({
                AnmID: AnmID,
                DtaDelTime: {$type: "undefined"},
            });
            return evts.sort((e1, e2) => e1.EvTime - e2.EvTime);
        } catch (e) {
            return [];
        }
    }

    //get All events for animal after specific day
    getEvents4AnimalAfterDate(AnmID, date) {
        try {
            return lokiDB.events.find({
                AnmID,
                DtaDelTime: {$type: "undefined"},
                EvTime: {$gt: date},
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getLastEvent4AnimalWithType(AnmID, type) {
        return lokiDB.events
            .chain()
            .find({
                AnmID,
                EvCode: type,
                DtaDelTime: {$type: "undefined"},
            })
            .simplesort("EvTime", {desc: true})
            .data()[0];
    }

    getAllEvents4AnimalWithType(AnmID, type) {
        try {
            return lokiDB.events.find({
                AnmID: AnmID,
                EvCode: type,
                DtaDelTime: {$type: "undefined"},
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * @deprecated - funkcja uzyta tylko w testach
     * @returns {*[]|*}
     */
    getAllEvents() {
        try {
            return lokiDB.events.find({DtaDelTime: {$type: "undefined"}});
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllEventsForFarm(FarmID) {
        try {
            if (!lokiDB.events) return [];
            return lokiDB.events.find({
                FarmID,
                DtaDelTime: {$type: "undefined"},
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllEventsWithRange(startDate, endDate, FarmID) {
        try {
            return lokiDB.events.find({
                EvTime: {$between: [startDate, endDate]},
                DtaDelTime: {$type: "undefined"},
                FarmID: FarmID,
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getEventsWithCodeInRange(startDate, endDate, EvCode, FarmID) {
        try {
            return lokiDB.events.find({
                EvTime: {$between: [startDate, endDate]},
                DtaDelTime: {$type: "undefined"},
                FarmID,
                EvCode,
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllEventsForFarmWithType(FarmID, type) {
        try {
            return lokiDB.events.find({
                DtaDelTime: {$type: "undefined"},
                FarmID: FarmID,
                EvCode: type,
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * @deprecated - funkcja uzyta tylko w testach
     * @param dateStart
     * @param dateEnd
     * @param AnmID
     * @returns {*[]|*}
     */
    getEventsByAnimalIDWithRange(dateStart, dateEnd, AnmID) {
        try {
            return lokiDB.events.find({
                EvTime: {$between: [dateStart, dateEnd]},
                DtaDelTime: {$type: "undefined"},
                AnmID: AnmID,
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllEventsWithEvTimeGreaterThanOrEqualsForAnimal(AnmID, EvTime) {
        try {
            return lokiDB.events
                .chain()
                .find({
                    AnmID,
                    EvTime: {
                        $gte: EvTime,
                    },
                    DtaDelTime: {$type: "undefined"},
                })
                .simplesort("EvTime")
                .data();
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getCurrentCycle(animal, toDate) {
        let events = this.getAllEvents4Animal(animal.AnmID).sort(
            (a, b) => a.EvTime - b.EvTime
        );
        if (toDate)
            events = events.filter(
                (e) =>
                    e.EvTime <= toDate.clone().endOf("day").toDate().getTime()
            );
        let cycles = convertRowsToCycles(preEvents(events, animal).cycleTable);
        return cycles[cycles.length - 1];
    }

    getEventCycle(animal, selectedDate) {
        return this.getCurrentCycle(animal, moment(selectedDate));
    }

    /**
     * @deprecated - funkcja nieuzywana
     * @param animal
     * @returns {number}
     */
    getPigBalanceForASowInCurrentCycle(animal) {
        try {
            let currentCycle = this.getCurrentCycle(animal);
            let eventsInCycle = [];
            for (let key in currentCycle) {
                if (Array.isArray(currentCycle[key])) {
                    eventsInCycle.push(...currentCycle[key]);
                }
            }
            return getPigBalance(eventsInCycle);
        } catch (e) {
            console.error(e);
            return 0;
        }
    }

    /**
     * @deprecated - funkcja nieuzywana (obecnie uzyta tylko w testach)
     * @param animal
     * @param selectedDate
     * @returns {number}
     */
    getPigBalanceForASow(animal, selectedDate) {
        if (!animal) return 0;
        //szukamy ostatniego porodu
        try {
            let lastBirth = this.getLastEvent4AnimalWithType(
                animal.AnmID,
                EventTypes.PARTURITION
            );
            if (!lastBirth) return 0;
            let evtz = this.getAllEventsWithEvTimeGreaterThanOrEqualsForAnimal(
                animal.AnmID,
                lastBirth.EvTime
            );
            selectedDate &&
                (evtz = evtz.filter((ev) =>
                    moment(+ev.EvTime)
                        .startOf("day")
                        .isSameOrBefore(selectedDate)
                ));
            return getPigBalance(evtz);
        } catch (e) {
            console.error(e);
            return 0;
        }
    }

    /**
     * @deprecated - prawdopodobnie nie bedziemy juz nigdzie uzywac tej funkcji, zastąpiono ją getPigBalanceInSpecificCycle, która wyszukuje aktualną ilość prosiąt dla konkretnego cyklu
     * @param animal
     * @param selectedDate
     * @returns {number}
     */
    getPigBalanceAllTime(animal, selectedDate) {
        let events = this.getAllEvents4Animal(animal.AnmID);
        events = events.filter((item) =>
            moment(+item.EvTime).startOf("day").isSameOrBefore(selectedDate)
        );
        return getPigBalance(events, false);
    }

    _getPigBalanceInCycle(animal, selectedDate) {
        try {
            let specificCycle = this.getEventCycle(animal, selectedDate);
            let eventsInCycle = [];
            for (let key in specificCycle) {
                if (
                    specificCycle.hasOwnProperty(key) &&
                    Array.isArray(specificCycle[key])
                ) {
                    eventsInCycle.push(...specificCycle[key]);
                }
            }
            return {
                cycle: specificCycle,
                balance: getPigBalance(eventsInCycle, true, false, false),
                fromMommy: getAliveAnimalsAmountFromMommyGiltEvents(
                    animal,
                    eventsInCycle
                ),
                tattooed: getAliveAnimalsAmountFromTattooEvents(
                    animal,
                    eventsInCycle
                ),
            };
        } catch (e) {
            console.error(e);
            return {cycle: null, balance: 0, tattooed: 0};
        }
    }

    /**
     * Function calculates pig balance in specific cycle. If you can, use getPigBalanceInSpecificCycle from EventUtils instead, to avoid unnecessary loki gets.
     * @param {*} animal
     * @param {*} selectedDate
     * @param {*} includeTattooing
     * @returns
     */
    getPigBalanceInSpecificCycle(animal, selectedDate, includeTattooing) {
        try {
            const pigBalance = this._getPigBalanceInCycle(
                animal,
                selectedDate,
                includeTattooing
            );
            if (includeTattooing) {
                return {
                    balance:
                        pigBalance.balance -
                        pigBalance.tattooed -
                        pigBalance.fromMommy,
                    tattooed: pigBalance.tattooed + pigBalance.fromMommy,
                };
            }
            return pigBalance.balance;
        } catch (e) {
            console.error(e);
            return 0;
        }
    }

    /**
     * @deprecated - funkcja nieuzywana
     * @param selectedDate
     * @param daysBack
     * @returns {number}
     */
    getNumberOfSeparatedPigsToMommy(selectedDate, daysBack) {
        const res = lokiDB.events.find({DtaDelTime: {$type: "undefined"}});
        if (res.length === 0) {
            return 0;
        }
        let evts = [];
        let amount = 0;
        if (!daysBack) {
            evts = res.filter(
                (ev) =>
                    ev.EvCode === EventTypes.SEPARATION_TO_MOMMY ||
                    ev.EvCode === EventTypes.MOMMY
            );
        } else {
            evts = res.filter(
                (ev) =>
                    (ev.EvCode === EventTypes.SEPARATION_TO_MOMMY ||
                        ev.EvCode === EventTypes.MOMMY) &&
                    selectedDate
                        .startOf("day")
                        .diff(moment(+ev.EvTime).startOf("day"), "days") >= 0 &&
                    selectedDate
                        .startOf("day")
                        .diff(moment(+ev.EvTime).startOf("day"), "days") <
                        daysBack
            );
        }
        evts.map((ev) => {
            let am = isNaN(+ev.EvData.PiCnt) ? 0 : +ev.EvData.PiCnt;
            if (ev.EvCode === EventTypes.SEPARATION_TO_MOMMY) {
                ev.EvData && ev.EvData.PiCnt && (amount += am);
            } else {
                ev.EvData && ev.EvData.PiCnt && (amount -= am);
            }
        });
        return amount;
    }

    /**
     * @param id
     * @returns {*}
     */
    getEventByID(id) {
        return lokiDB.events.findOne({EvID: id});
    }

    doesFarmHasSomeEvent(FarmID) {
        return lokiDB.events.findOne({FarmID});
    }

    /**
     * @deprecated - funkcja nieuzywana
     * @param FarmID
     * @returns {*|number}
     */
    getFirstEventDate(FarmID) {
        let res = lokiDB.events.find({FarmID: FarmID});
        res.sort((a, b) => a.EvTime - b.EvTime);
        return res[0] ? res[0].EvTime : new Date().getTime();
    }

    /**
     * @deprecated - funkcja nieuzywana
     * @param AnmID
     * @param minData
     * @param maxDate
     * @param type
     * @returns {*}
     */
    getEventsForAnimalWithinDateRangeAndType(AnmID, minData, maxDate, type) {
        return lokiDB.events.find({
            AnmID,
            EvTime: {
                $between: [minData, maxDate],
            },
            EvCode: type,
        });
    }

    /**
     * Metoda wyszukująca ilość sutków dla zwierzęcia
     * @param AnmID {string}    ID zwierzęcia
     * @param date {number}     timestamp, maksymalna czas zgloszenia ilosci sutkow
     * @return {number | null}  ilość aktywnych sutków w podanym czasie lub null kiedy nie odnaleziono danych
     */
    getActiveNipples(AnmID, date = new Date().getTime()) {
        let evs = lokiDB.events
            .find({
                AnmID,
                EvCode: EventTypes.ACTIVE_NIPPLES,
                EvTime: {$lte: date},
            })
            .sort((a, b) => b.EvTime - a.EvTime);
        let event = evs[0];
        console.log(event);
        if (event) {
            return event.EvData.Nipples;
        }
        let animal = animalsDB.getAnimalById(AnmID);
        if (isNil(animal.ActiveNipples)) return animal.ActiveNipples;
        return null;
    }

    getActiveNipplesInCurrentCycle(animal) {
        let currentCycle = this.getCurrentCycle(animal);
        if (currentCycle) {
            let activeNipplesArray = currentCycle[EventTypes.ACTIVE_NIPPLES];
            activeNipplesArray.sort((a, b) => b.EvTime - a.EvTime);
            if (activeNipplesArray[0]) {
                return activeNipplesArray[0].EvData.Nipples;
            }
        }
        if (isNil(animal.ActiveNipples)) return animal.ActiveNipples;
        return null;
    }

    calculateMaxAmountOfAddedPiglets(FarmID) {
        let evs = lokiDB.events.find({
            FarmID,
            $or: [
                {EvCode: EventTypes.SEPARATION_TO_MOMMY},
                {EvCode: EventTypes.MOMMY},
            ],
            DtaDelTime: {$type: "undefined"},
        });
        let amount = 0;
        console.log(evs);
        for (let ev of evs) {
            if (ev.EvCode === EventTypes.MOMMY) {
                amount -= ev.EvData.PiCnt;
            } else {
                amount += ev.EvData.PiCnt;
            }
        }
        return amount;
    }

    removeTemporaryEvent(EvID) {
        lokiDB.events.chain().find({EvID, isTemporary: true}).remove();
    }
}

const eventsDB = new Events();
export default eventsDB;
