import {isString, isNil, get} from "lodash";
import {isFiniteNumber} from "./MathUtils";
import {isDarkMode} from "./DOMUtils";

export function colorHexToRGB(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        }
        : null;
}

export function rgbToHex(r, g, b) {
    return (
        "#" +
        [r, g, b]
            .map((x) => {
                const hex = x.toString(16);
                return hex.length === 1 ? "0" + hex : hex;
            })
            .join("")
    );
}

export function hexStringToNumeric(hexString) {
    let number = 0;
    try {
        const string = hexString.replace("#", "");
        number = parseInt(string, 16);
    } catch (er) {
        console.error(er);
    }
    return isFiniteNumber(number) ? number : 0;
}

export function numericToHexString(numericValue) {
    const r = (numericValue & 0xff0000) >> 16,
        g = (numericValue & 0x00ff00) >> 8,
        b = numericValue & 0x0000ff;
    return rgbToHex(r, g, b);
}

export function getColorForPercentage(percentage = 0) {
    // slupki na zielono od 70% pomaranczowy od 30 do 70% czerwony do 30%
    let fillColor = "error";
    if (percentage >= 30) {
        fillColor = "warning";
    }
    // if (percentage >= 40) {
    //     fillColor = "info";
    // }
    if (percentage >= 70) {
        fillColor = "success";
    }
    return fillColor;
}

export const colors = {
    success: "#4eb848",
    primary: "#4eb848",
    secondary: "#4C4C4E",
    error: "#B90E0E",
    info: "#2196f3",
    warning: "#ffc107",
    white: "#ffffff",
    colorLightText: "#ffffff",
    colorDarkText: "#000000",
    red: "#B90E0E",
    birth: "#754D88",
    mating: "#4D884D",
    sows: "#E19A21",
    piglets: "#CE5993",
    other: "#753F31",
    porkers: "#01af7e",
};

export const colorsNonInvasive = {
    teal: "#009688",
    pink: "#e91e63",
    blue: "#2196f3",
    orange: "#ffc107",
    cyan: "#00bcd4",
    yellow: "#ffeb3b",
    green: "#4eb848",
    deeporange: "#ff5722",
    purple: "#9c27b0",
};

export const diodeColors = [
    "#00FF00",
    "#00FFFF",
    "#FFFF00",
    "#FF00FF",
    "#FFC000",
    "#FF0000",
    "#fcf7a5",
    "#0000FF",
    "#C8FF00",
    "#2196f3",
    "#9c00ff",
    "#6DAEB0",
];

export const themeDependentColors = {
    dark: {
        background: "#1e1e1e",
        background50: "#2d2d2d",
        background100: "#2a2a2a",
        background200: "#272727",
        background300: "#242424",
        background400: "#212121",
        background500: "#1e1e1e",
        background600: "#1b1b1b",
        background700: "#181818",
        background800: "#151515",
        background900: "#121212",
        disabled: "#c8c8c8",
        map: {
            forestBG: "#80e27e",
            forestFG: "#4caf50",
        },
        fontColor: "#DCDDDE"
    },
    light: {
        background: "#a5a5a5",
        background50: "#f6f6f6",
        background100: "#f1f1f1",
        background200: "#f1f1f1",
        background300: "#e6e6e6",
        background400: "#c4c4c4",
        background500: "#a5a5a5",
        background600: "#7c7c7c",
        background700: "#676767",
        background800: "#484848",
        background900: "#262626",
        disabled: "#aaaaaa",
        map: {
            forestBG: "#80e27e",
            forestFG: "#4caf50",
        },
        fontColor: "#000000"
    },
};
export const defaultSwatchesColors = [
    ["#b71c1c", "#d32f2f", "#f44336", "#e57373", "#ffcdd2"],
    ["#880e4f", "#c2185b", "#e91e63", "#f06292", "#f8bbd0"],
    ["#4a148c", "#7b1fa2", "#9c27b0", "#ba68c8", "#e1bee7"],
    ["#311b92", "#512da8", "#673ab7", "#9575cd", "#d1c4e9"],
    ["#1a237e", "#303f9f", "#3f51b5", "#7986cb", "#c5cae9"],
    ["#0d47a1", "#1976d2", "#2196f3", "#64b5f6", "#bbdefb"],
    ["#01579b", "#0288d1", "#03a9f4", "#4fc3f7", "#b3e5fc"],
    ["#006064", "#0097a7", "#00bcd4", "#4dd0e1", "#b2ebf2"],
    ["#004d40", "#00796b", "#009688", "#4db6ac", "#b2dfdb"],
    ["#194D33", "#388e3c", "#4caf50", "#81c784", "#c8e6c9"],
    ["#33691e", "#689f38", "#8bc34a", "#aed581", "#dcedc8"],
    ["#827717", "#afb42b", "#cddc39", "#dce775", "#f0f4c3"],
    ["#f57f17", "#fbc02d", "#ffeb3b", "#fff176", "#fff9c4"],
    ["#ff6f00", "#ffa000", "#ffc107", "#ffd54f", "#ffecb3"],
    ["#e65100", "#f57c00", "#ff9800", "#ffb74d", "#ffe0b2"],
    ["#bf360c", "#e64a19", "#ff5722", "#ff8a65", "#ffccbc"],
    ["#3e2723", "#5d4037", "#795548", "#a1887f", "#d7ccc8"],
    ["#263238", "#455a64", "#607d8b", "#90a4ae", "#cfd8dc"],
    ["#000000", "#525252", "#969696", "#D9D9D9", "#FFFFFF"],
];

export const deleteSimilarColors = (colors, data = defaultSwatchesColors) => {
    const accuracy = 0.2;

    for (let i = 0; i < colors.length; i++) {
        const color = colors[i];
        const [h1, s1, l1] = hexToHsl(color);

        const filteredArray = data.map((pivotArray) => {
            const filteredColors = pivotArray.filter((k) => {
                const [h2, s2, l2] = hexToHsl(k);

                const hue = Math.abs(h1 - h2);
                const saturation = Math.abs(s1 - s2);
                const lightness = Math.abs(l1 - l2);

                if (
                    hue <= accuracy &&
                    saturation <= accuracy &&
                    lightness <= accuracy
                ) {
                    return false;
                }

                return true;
            });

            return filteredColors;
        });

        data = filteredArray;
    }

    return data;
};
export const hexToHsl = (hexColor) => {
    const r = parseInt(hexColor.slice(1, 3), 16) / 255;
    const g = parseInt(hexColor.slice(3, 5), 16) / 255;
    const b = parseInt(hexColor.slice(5, 7), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l;

    l = (max + min) / 2;

    if (max === min) {
        h = s = 0;
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
            default:
                break;
        }

        h /= 6;
    }

    return [h, s, l];
};

export function getColorByName(color = "primary") {
    if (color === "currentColor") return color;
    return color.startsWith("#")
        ? color
        : get(
            {
                ...colors,
                ...colorsNonInvasive,
                ...themeDependentColors[isDarkMode() ? "dark" : "light"],
            },
            color,
            "#000000"
        );
}

export function getColorByIndex(index = Math.floor(Math.random() * 1000)) {
    return colorsNonInvasive[
        Object.keys(colorsNonInvasive)[
        index % Object.keys(colorsNonInvasive).length
            ]
        ];
}

function pickHex(color1, color2, weight) {
    let p = weight;
    let w = p * 2 - 1;
    let w1 = (w / 1 + 1) / 2;
    let w2 = 1 - w1;
    let rgb = [
        Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2),
    ];
    return rgb;
}

export function getColorInGradient(
    percentage = 0,
    gradientDefinition = [
        {percent: 0, color: getColorByName("error")},
        {percent: 40, color: getColorByName("warning")},
        {percent: 75, color: getColorByName("info")},
        {percent: 100, color: getColorByName("success")},
    ]
) {
    let gradient;
    percentage = Math.round(percentage);
    percentage = Math.min(percentage, 100);
    percentage = Math.max(percentage, 0);
    if (gradientDefinition.length < 2)
        throw new Error("Gradient must be made of 2 or more colors");
    gradient = gradientDefinition.map((point) => {
        let percent = point.percent;
        let rgb = colorHexToRGB(point.color);
        rgb = rgb ? [rgb.r, rgb.g, rgb.b] : [0, 0, 0];
        return [percent, rgb];
    });
    let colorRange = [0, 1];
    if (percentage !== 0) {
        for (let index = 1; index < gradient.length; index++) {
            if (percentage <= gradient[index][0]) {
                colorRange = [index - 1, index];
                break;
            }
        }
    }
    //console.log("colorRange", colorRange);
    let firstcolor = gradient[colorRange[0]][1];
    let secondcolor = gradient[colorRange[1]][1];
    //console.log(firstcolor, "SECOND", secondcolor);

    let firstcolor_x = 100 * (gradient[colorRange[0]][0] / 100);
    let secondcolor_x = 100 * (gradient[colorRange[1]][0] / 100) - firstcolor_x;
    let slider_x = 100 * (percentage / 100) - firstcolor_x;
    let ratio = slider_x / secondcolor_x;

    let result = pickHex(secondcolor, firstcolor, ratio);

    return "rgb(" + result.join() + ")";
}

export function getColorByStringToIndex(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let c = hash & 0x00ffffff;
    return getColorByIndex(c);
}

export function getColorByString(str, {appendHash = false} = {}) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let c = (hash & 0x00ffffff).toString(16).toUpperCase();
    const value = "00000".substring(0, 6 - c.length) + c;
    return appendHash ? `#${value}` : value;
}

export function brightness(color) {
    if (isString(color)) {
        if (color.startsWith("rgb")) {
            color = color
                .split("(")[1]
                .split(")")[0]
                .split(",")
                .map((o) => +o || 0);
        } else {
            color = colorHexToRGB(color);
            color = color ? [color.r, color.g, color.b] : [0, 0, 0];
        }
    } else {
        color = color ? [color.r, color.g, color.b] : [0, 0, 0];
    }
    return Math.round(
        color[0] * 299 + color[1] * 587 + (color[2] * 114) / 1000
    );
}

export function textContrast(
    color,
    light = getColorByName("colorLightText"),
    dark = getColorByName("colorDarkText")
) {
    if (isNil(color)) {
        return color;
    } else {
        let colorBrightness = brightness(color);
        let lightTextBrightness = brightness(light);
        // let darkTextBrightness = brightness(dark);
        return Math.abs(colorBrightness - 30000) < lightTextBrightness / 2
            ? light
            : dark;
    }
}

export function setBackgroundColorInMeta() {
    setTimeout(() => {
        let root = document.getElementById("root");
        let css = getComputedStyle(root).getPropertyValue("background-color");
        let rgbArray = css
            .split("(")[1]
            .split(")")[0]
            .split(",")
            .map((item) => +item);
        let hex = rgbToHex(...rgbArray);
        document
            .querySelector('meta[name="theme-color"]')
            .setAttribute("content", hex);
    });
}

function lightenDarkenColor(color, amount) {
    const numeric = hexStringToNumeric(color);
    const lighten = (value, amount) => {
        const newValue = value + amount;
        return newValue > 255 ? 255 : newValue < 0 ? 0 : Math.round(newValue);
    };
    return rgbToHex(
        lighten(numeric >> 16, amount),
        lighten((numeric >> 8) & 0x00ff, amount),
        lighten(numeric & 0x0000ff, amount)
    );
}

export function darken(color, amount) {
    return lightenDarkenColor(color, -amount);
}

export function lighten(color, amount) {
    return lightenDarkenColor(color, amount);
}

export function getColorMobileResult(previous, current, revert) {
    if (current > previous) return revert ? "error" : "success";
    else if (current < previous) return revert ? "success" : "error";
    return null;
}

export const getColorByHashedKey = (key) => {
    const colors = deleteSimilarColors([
        themeDependentColors.dark.background50,
        themeDependentColors.light.background50,
    ])
    let hash = 0;
    for (let i = 0; i < key.length; i++) {
        hash = key.charCodeAt(i) + ((hash << 5) - hash);
    }

    const arrayIndex = Math.abs(hash) % colors.length;
    const colorIndex = Math.abs(hash) % colors[arrayIndex].length;

    return colors[arrayIndex][colorIndex];
};