import React, {Component} from 'react';
import {connect} from 'react-redux';
import DevType from "@wesstron/utils/Api/constants/devTypes";
import {Level} from "../../../constans/levelTypes";
import memoizeOne from "memoize-one";
import {debounce, isEmpty, memoize} from "lodash";
import {Milliseconds} from "../../../constans/milliseconds";
import NewIOT from "../../../IOT/NewIOT";
import sinon from "sinon";
import {fakeSendMapState, fakeCreateAndSendMessageObject} from "../../../demo/map/fakeFunctions";
import {isUsingFakeData} from "../../../utils/DemoUtils";
import ReactDOM from "react-dom";
import GatewayResponse from "./GatewayResponse";


const thingTypes = [DevType.GATEWAY, DevType.ALARM_CONTROL_PANEL];

const getThings = memoizeOne((devices) => devices.filter((d) => thingTypes.includes(d.DevType)));

function makeMapStateToProps(state) {
    return {
        things: getThings(state.farmDevices.devices)
    }
}

const MaxTime = 5 * Milliseconds.MINUTE;

/**
 * todo: PRZEPISAĆ NA HOOKI W WOLNYM CZASIE
 */
class FarmDataGetter extends Component {

    constructor(props) {
        super(props);
        this.requestMap = {};
        this.timeoutTime = {};
        this.destoryed = false;
        this.state = {
            response: {}
        }
        this.demo = isUsingFakeData();
        if (this.demo) {
            sinon.restore();
            sinon.stub(NewIOT, "sendMapState").callsFake(fakeSendMapState);
            sinon.stub(NewIOT, "createAndSendMessageObject").callsFake(fakeCreateAndSendMessageObject);

        }
    }


    getViewedItems = memoizeOne((elementsInViewBox) => {
        const items = elementsInViewBox || [];
        const viewing = [];
        items.forEach((item) => {
            if (item.location && [Level.CHAMBER, Level.BUILDING].includes(item.location.level)) {
                viewing.push({id: item.location.id, type: "Locations"});
            } else if (item.type === "devices") {
                viewing.push({id: item.devices[0].device.DevID, type: "Devices"});
            }

        })
        return viewing;
    })

    componentWillUnmount() {
        document.removeEventListener("visibilitychange", this.onVisibilityChange);
        this.destoryed = true;
        this.cancelCheck();
        this.sendRequest.cancel();
        if (this.demo) {
            sinon.restore();
        }
    }

    getData = async (GatewayID, items) => {
        return new Promise((resolve, reject) => {
            NewIOT.sendMapState(GatewayID, items, {onSuccess: resolve, onFailure: reject});
        })
    }

    cancelCheck = () => {
        clearTimeout(this.timeout);
    }

    onVisibilityChange = () => {
        if (document.visibilityState === "visible") {
            this.nextCheck(0); // force refresh
        }
    }

    nextCheck = (time) => {
        if (this.destoryed) return;
        this.cancelCheck();
        this.timeout = setTimeout(() => {
            this.sendRequest();
        }, time);
    }

    resolveNameByIdManager = memoizeOne((things) => {
        return memoize((id) => things.find((thing) => thing.DevID === id)?.Name ?? id);
    })

    sendRequest = debounce(() => {
        const data = {};
        const now = Date.now();
        let nextCheck = MaxTime;
        for (let [id, values] of Object.entries(this.requestMap)) {
            if (values._active) {
                for (let [GwID, sendTime] of Object.entries(values)) {
                    if (GwID.startsWith("_")) continue;
                    const timeDiff = Math.min(now - +sendTime, MaxTime);
                    if (timeDiff === MaxTime) {
                        if (!data[GwID]) data[GwID] = {};
                        data[GwID][id] = values._key;
                        this.requestMap[id][GwID] = now;
                    } else {
                        nextCheck = Math.min(Math.max(MaxTime - timeDiff, 1000), nextCheck);
                    }

                }
            }
        }
        for (let [GwID, items] of Object.entries(data)) {
            if (!isEmpty(items)) {
                const tmp = {
                    Locations: [],
                    Devices: []
                }
                for (let [id, key] of Object.entries(items)) {
                    tmp[key].push(id);
                }
                let response = {type: "loading", requestedAt: Date.now(), responseTime: 0};
                this.setState((state) => ({
                    ...state,
                    response: {
                        ...state.response,
                        [GwID]: response
                    }
                }));
                this.getData(GwID, tmp).then(() => {
                    this.timeoutTime[GwID] = 0;
                    response.type = "success";
                    response.responseTime = Date.now() - response.requestedAt;
                }).catch(() => {
                    response.type = "error";
                    if (!this.timeoutTime[GwID]) this.timeoutTime[GwID] = 500;
                    this.timeoutTime[GwID] *= 2;
                    this.timeoutTime[GwID] = Math.min(this.timeoutTime[GwID], MaxTime);
                    const time = this.timeoutTime[GwID];
                    for (let id of Object.keys(items)) {
                        if (this.requestMap[id][GwID]) {
                            this.requestMap[id][GwID] = now - MaxTime + time;
                        }
                    }
                    this.nextCheck(time);
                }).finally(() => {
                    try {
                        if (!this.destoryed) {
                            this.setState((state) => ({
                                ...state,
                                response: {
                                    ...state.response,
                                    [GwID]: (response.requestedAt === state.response[GwID]?.requestedAt) ? response : state.response[GwID]
                                }
                            }))
                        }
                    } catch (e) {

                    }
                })
            }
        }
        this.nextCheck(nextCheck);

    }, 500, {maxWait: 20000});

    componentDidMount() {
        const {things, elementsInViewBox} = this.props;
        const viewing = this.getViewedItems(elementsInViewBox);
        for (let {id, type} of viewing) {
            const req = this.requestMap;
            req[id] = req[id] || {};
            req[id]._active = true;
            req[id]._key = type;
            things.forEach(({DevID}) => {
                if (!req[id][DevID]) req[id][DevID] = 0;
            })
        }
        this.sendRequest();
        document.addEventListener("visibilitychange", this.onVisibilityChange);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {things, elementsInViewBox} = this.props;
        const thingsChange = prevProps.things !== things;
        const itemsChange = prevProps.elementsInViewBox !== elementsInViewBox;
        if (thingsChange || itemsChange) {
            const viewing = this.getViewedItems(elementsInViewBox);
            const req = this.requestMap;
            for (let key of Object.keys(req)) {
                req[key]._active = false;
            }
            for (let {id, type} of viewing) {
                req[id] = this.requestMap[id] || {};
                req[id]._active = true;
                req[id]._key = type;
                things.forEach(({DevID}) => {
                    if (!req[id][DevID]) req[id][DevID] = 0;
                })
            }
            this.sendRequest();
        }
    }

    render() {
        return ReactDOM.createPortal((
            <div>
                {
                    Object.keys(this.state.response).map((GwID) => (
                        <GatewayResponse name={this.resolveNameByIdManager(this.props.things)(GwID)}
                                         response={this.state.response[GwID]} key={GwID}/>
                    ))
                }
            </div>), document.getElementById("response-container") || document.createElement("div"));
    }
}

export default connect(
    makeMapStateToProps,
)(FarmDataGetter);
